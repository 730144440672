import { React, useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import spinner from '../assets/images/refresh.png';
import { getReportingDates } from '../adapters/apiCalls';
import { colorMapping } from '../lib/mappings';
import { makeNiceStatusName } from '../lib/ReportingDateHelper';
import Header from './Header';
import Navbar from './Navbar';
import { JobButonWidget } from './Jobs';
import { ScenarioButtonWidget } from './Scenarios';
import { calcMethods } from './App';
import { CalcMethod } from './CalcMethods';
import Content from './Content';
import { Sidebar, SidebarButton } from './Sidebar';
import Main from './Main';
import { Info, AppError } from './Alert';
import { Preparations } from './Preparations'

const ERROR_RETRY_TIMEOUT = Number(process.env.REACT_APP_ERROR_RETRY_TIMEOUT) || 0;

export const ReportingDate = ({ job, updateJob }) => {
  let { path } = useRouteMatch();
  const selectedReportingDateId = job.selectedReportingDate;
  const selectedReportingDateObj = selectedReportingDateId ? job.reportingDates[selectedReportingDateId] : null;
  let cachedReportingDate = selectedReportingDateObj;

  const getActiveReportingDate = () => {
    return job.reportingDates[job.activeReportingDate];
  };

  const updateReportingDate = (updatedReportingDate, reportingDateId) => {
    // Update app state with extended details of reporting date
    if (reportingDateId !== selectedReportingDateId) {
      console.log('Unexpected FATAL ERROR in "ReportingDates" updateReportingDate()');
      return;
    }
    const newReportingDate = { ...cachedReportingDate, ...updatedReportingDate };
    cachedReportingDate = newReportingDate;
    const newReportingDates = { ...job.reportingDates, [reportingDateId]: newReportingDate };
    updateJob({ reportingDates: newReportingDates }, job.id);
  };

  return (
    <>
      <Switch>
        {job.id === 'calculations' ? <Route path={`${path}/reporting_dates/:reportingDateId/calc_methods`}>
          <CalcMethod
            reportingDate={selectedReportingDateObj}
            getActiveReportingDate={getActiveReportingDate}
            updateReportingDate={updateReportingDate}
          /></Route>
          : job.id === 'preparation' ? <Route path={`${path}/reporting_dates/:reportingDateId`}>
              <Preparations job={job} updateJob={updateJob}/>
            </Route>
          : <Info message={'not implemented yet'}/>}

        {job.id === 'calculations'? <Route path={path}>
          <ReportingDateView job={job} updateJob={updateJob} />
        </Route>: job.id === 'preparation'? <Route path={path}>
          <ReportingDateView job={job} updateJob={updateJob} />
        </Route>: <Info message={'not implemented yet'}/>}
      </Switch>
    </>
  );
};

export const createUpdateObject = (loadedDates, job) => {
  // Add/Update reporting dates to job
  const updateObject = {};
  let newReportingDates = job.reportingDates ? { ...job.reportingDates } : loadedDates;
  for (const reportingDate of Object.values(loadedDates)) {
    if (newReportingDates[reportingDate.value]) {
      // Update existing
      newReportingDates[reportingDate.value] = UpdateReportingDateStatus(newReportingDates[reportingDate.value], reportingDate);
    } else {
      // Add new
      newReportingDates[reportingDate.value] = reportingDate;
    }
    setSpecificReportingDate(updateObject, newReportingDates[reportingDate.value]);
  }

  // remove not existing ones
  const loadedDateKeys = Object.keys(loadedDates);
  newReportingDates = Object.fromEntries(Object.entries(newReportingDates).filter(([key]) => loadedDateKeys.includes(key)));

  updateObject.reportingDates = newReportingDates;
  if (job.selectedReportingDate && !loadedDates[job.selectedReportingDate]) {
    // unset if removed
    updateObject.selectedReportingDate = null;
  }
  return updateObject;
};

const UpdateReportingDateStatus = (oldDate, newDate) => {
  // During update - update statuses as necessary
  const merged = { ...oldDate };
  merged.status = newDate.status;
  return merged;
};

const setSpecificReportingDate = (updateObj, reportingDate) => {
  // During update - update newReportingDate, activeReportingDate etc.
  if (reportingDate.status === 'A') {
    updateObj.activeReportingDate = reportingDate.value;
  }
  if (reportingDate.status === 'N') {
    updateObj.newReportingDate = reportingDate.value;
  }
  if (reportingDate.status === 'C') {
    updateObj.closedReportingDate = reportingDate.value;
  }
};


const ReportingDateView = ({ job, updateJob }) => {
  let { path } = useRouteMatch();
  const { scenarioId } = useParams();
  const jobId = job.id;
  const activeReportingDateObject = job.activeReportingDate ? job.reportingDates[job.activeReportingDate] : null;
  const selectedReportingDateObject = job.selectedReportingDate ? job.reportingDates[job.selectedReportingDate] : null;
  const title = `ifrs17/${scenarioId}/${jobId}/reporting-dates`;
  const calculationMethods = { ...calcMethods };

  const setReportingDates = (loadedDates) => {
    const updateObject = createUpdateObject(loadedDates, job);
    updateJob(updateObject, jobId);
  };

  const setSelectedReportingDate = (reportingDateId) => {
    updateJob({ selectedReportingDate: reportingDateId }, jobId);
  };

  return (
    <div id="reportingDates">
      <Header title={title} scenario={scenarioId} activeReportingDate={activeReportingDateObject} />
      <Navbar>
        <ScenarioButtonWidget selectedScenario={scenarioId} />
        <JobButonWidget selectedScenario={scenarioId} selectedJob={jobId} />
        <ReportingDateButtonWidget selectedJob={jobId} selectedScenario={scenarioId} selectedReportingDate={selectedReportingDateObject} />
      </Navbar>
      <Content>
        <Sidebar>
          <SidebarButton label="Scenarios" route="/scenarios/load" />
          <SidebarButton label="Jobs" route={`/scenarios/${scenarioId}`} />
          <SidebarButton label="Reporting dates" isActive={true} route={`/scenarios/${scenarioId}/jobs/${jobId}`} />
          {job.selectedReportingDate &&
            Object.keys(calculationMethods).map((calcMethodId) => (
                <SidebarButton
                  label={calcMethodId}
                  key={calcMethodId}
                  route={`/scenarios/${scenarioId}/jobs/${jobId}/reporting_dates/${job.selectedReportingDate}/calc_methods/load/${calcMethodId}`}
                />
              ))
            }
        </Sidebar>
        <Main>
          <Switch>
            <Route exact path={path}>
              <ReportingDateLoad setReportingDates={setReportingDates} />
            </Route>
            <Route path={`${path}/reporting_dates/:reportingDateId`}>
              <ReportingDateDetails selectedReportingDate={selectedReportingDateObject} />
            </Route>
            <Route path={`${path}/reporting_dates`}>
              <ReportingDateList reportingDates={job.reportingDates} setSelectedReportingDate={setSelectedReportingDate} />
            </Route>
          </Switch>
        </Main>
      </Content>
    </div>
  );
};

const ReportingDateLoad = ({ setReportingDates }) => {
  const history = useHistory();
  const [timerHandler, setTimerHandler] = useState(null);
  const { scenarioId, jobId } = useParams();
  const [error, setError] = useState(null);
  const colorTheme = colorMapping[jobId];
  let isMounted = false;
  const handleApiCall = () => {
    if (!isMounted) return;
    getReportingDates(scenarioId)
      .then((response) => {
        if (!isMounted) return;
        const { error, data } = response;
        if (error) {
          throw error;
        }
        return setReportingDates(data);
      })
      .then(() => {
        isMounted && history.push(`/scenarios/${scenarioId}/jobs/${jobId}/reporting_dates`);
      })
      .catch((error) => {
        console.log('Error', error);
        if (isMounted) {
          setError(error);
          const timerId = setTimeout(() => {
            handleApiCall();
          }, ERROR_RETRY_TIMEOUT);
          setTimerHandler(timerId);
        }
      });
  };

  useEffect(() => {
    isMounted = true; // eslint-disable-line react-hooks/exhaustive-deps
    handleApiCall();
    return () => {
      setError(null);
      timerHandler && clearTimeout(timerHandler) && setTimerHandler(null);
      isMounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {error ? <AppError error={error} setError={setError} /> : null}
      <div className="w3-right">
        <img src={spinner} className="w3-margin-bottom w3-padding w3-spin" alt="Loading..."></img>
      </div>
      <div className={`w3-block ${colorTheme} w3-large w3-margin-bottom w3-padding w3-mobile w3-border`}>Reporting dates</div>
    </>
  );
};

const ReportingDateList = ({ reportingDates, setSelectedReportingDate }) => {
  const { scenarioId, jobId } = useParams();
  const history = useHistory();
  const isReportingDates = reportingDates && !!Object.keys(reportingDates).length;

  const clickHandler = (reportingDateId) => {
    setSelectedReportingDate(reportingDateId);
    history.push(`/scenarios/${scenarioId}/jobs/${jobId}/reporting_dates/${reportingDateId}`);
  };

  return (
    <div id="rd_list_container" className={isReportingDates ? 'w3-border' : ''}>
        <>
          <div className="w3-container w3-theme-d1">
            <h3>Select Reporting Date</h3>
          </div>
          <div>
            {isReportingDates ? Object.values(reportingDates).map((reportingDate) => (
              <ReportingDateItemButton key={reportingDate.value} reportingDate={reportingDate} clickHandler={clickHandler} />
            )) : <h3>No data available</h3>}
          </div>
        </>
    </div>
  );
};

export const ReportingDateButtonWidget = ({ selectedJob, selectedScenario, selectedReportingDate }) => {
  const selectedReportingDateId = selectedReportingDate && selectedReportingDate.value;
  const selectedReportingDateStatus = selectedReportingDate && selectedReportingDate.status;
  const history = useHistory();

  const clickHandler = () => {
    history.push(`/scenarios/${selectedScenario}/jobs/${selectedJob}`);
  };

  return (
    <>
      {selectedReportingDateId && <div className={`w3-bar-item ${colorMapping[selectedReportingDateStatus]} w3-button w3-ripple w3-mobile w3-border`} onClick={clickHandler}>
        {selectedReportingDateId}
      </div>}
    </>
  );
};

const ReportingDateItemButton = ({ reportingDate, clickHandler }) => {
  return (
    <div className="w3-button w3-block w3-mobile" onClick={() => clickHandler(reportingDate.value)}>
      <ReportingDateDisplay reportingDate={reportingDate} />
    </div>
  );
};

const ReportingDateDetails = ({selectedReportingDate}) => {
  return (
    <>
    <div id="rd_default_content" className="w3-border w3-margin-bottom">
      <TitledDetailedReportingDateDisplay selectedReportingDate={selectedReportingDate} title="Selected Reporting Date" />
    </div>
    <div className='w3-left'>Note: Choose actions from the navigation bars</div>
    </>
  );
};

export const TitledDetailedReportingDateDisplay = ({ selectedReportingDate, title, subTitle }) => {

  return (
    <>
      {title ? (
        <div className="w3-container w3-theme-d1">
          <h3>{title}</h3>
        </div>
      ) : null}
      {subTitle ? (
        <div className="w3-container">
          <h4>{subTitle}</h4>
        </div>
      ) : null}
      <div className="w3-panel">
        {selectedReportingDate && selectedReportingDate.value ? (
          <DetailedReportingDateDisplay reportingDate={selectedReportingDate} />
        ) : (
          <Info message="No Reporting Dates available, may create a new one first on 'maintenance' page." />
        )}
      </div>
    </>
  );
};

const DetailedReportingDateDisplay = ({ reportingDate }) => {
  const status = reportingDate.status;
  const niceName = makeNiceStatusName(status);

  return (
    <>
      <div className="w3-margin-top w3-margin-bottom">
        <ReportingDateDisplay reportingDate={reportingDate} />
      </div>

      <div className="w3-padding-top w3-padding-bottom w3-border">
        <div className="w3-bar">
          <div className="w3-bar-item">
            <strong>Status:</strong>
          </div>
          <div className="w3-bar-item">{niceName}</div>
        </div>
      </div>
    </>
  );
};

const ReportingDateDisplay = ({ reportingDate }) => {
  const status = reportingDate.status;
  const reportingDateId = reportingDate.value;
  const colorTheme = colorMapping[status];

  return (
    <>
      <span className={`w3-tag w3-large ${colorTheme} w3-padding w3-margin-right`} style={{ width: 70 + 'px' }}>
        {status}
      </span>
      <span>{reportingDateId}</span>
    </>
  );
};

export { ReportingDateView, ReportingDateLoad, ReportingDateList, ReportingDateItemButton, ReportingDateDetails, DetailedReportingDateDisplay, ReportingDateDisplay };