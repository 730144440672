import { niceNameMapping } from './mappings'

/**
  Status IDs:
  'N' - New
  'A' - Active
  'C' - Closed
  'R' - Closed, Read Only
 */

export const makeNiceStatusName = (reportingDateStatus) => {
  return niceNameMapping[reportingDateStatus] || reportingDateStatus;
};

export const sortData = (dataObj) => Object.fromEntries(Object.entries(dataObj).sort().reverse());

export const transformFetchedReportingDates = (dataArray) => {
  const result = {};
  dataArray.forEach(item => result[item.value] = item);
  return result;
};
