import { React, useEffect, useState } from 'react';
import spinner from '../assets/images/refresh.png';
import { getScenarios } from '../adapters/apiCalls';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';
import Header from './Header';
import Navbar from './Navbar';
import Content from './Content';
import Main from './Main';
import { Jobs } from './Jobs';
import { colorMapping } from '../lib/mappings';
import { Sidebar, SidebarButton } from './Sidebar';
import { AppError } from './Alert';

const ERROR_RETRY_TIMEOUT = Number(process.env.REACT_APP_ERROR_RETRY_TIMEOUT) || 0;

export const Scenarios = ({ store, setStore }) => {
  const { path } = useRouteMatch();
  const scenarios = store.scenarios;
  const selectedScenarioId = store.selectedScenario;
  const selectedScenario = scenarios && selectedScenarioId ? scenarios[selectedScenarioId] : null;

  // This function is passed to 'Jobs' for updating state supporting partial updates
  const updateScenario = (updatedScenario, scenarioId) => {
    if (scenarioId !== selectedScenarioId) {
      console.log('Unexpected FATAL ERROR in "Scenarios" updateScenario()');
      return;
    }
    const newScenario = { ...selectedScenario, ...updatedScenario };
    const newScenarios = { ...scenarios, [scenarioId]: newScenario };
    setStore({ scenarios: newScenarios });
  };

  return (
    <>
      <Switch>
        <Route path={`${path}/load`}>
          <ScenarioView scenarios={scenarios} selectedScenario={selectedScenario} setStore={setStore} extra="load" />
        </Route>
        <Route path={`${path}/:scenarioId`}>
          <Jobs scenario={selectedScenario} updateScenario={updateScenario} />
        </Route>
        <Route path={path}>
          <ScenarioView scenarios={scenarios} selectedScenario={selectedScenario} setStore={setStore} />
        </Route>
      </Switch>
    </>
  );
};

const ScenarioView = ({ scenarios, selectedScenario, setStore, extra }) => {
  let { path } = useRouteMatch();
  const title = `ifrs17/scenarios`;
  const selectedScenarioId = selectedScenario ? selectedScenario.id : null;

  const setScenarios = (loadedScenarios) => {
    // loadedScenarios is array of strings
    let newScenarios = scenarios ? { ...scenarios } : loadedScenarios;
    for (const scenario of loadedScenarios) {
      if (!newScenarios[scenario]) {
        // Adding only
        newScenarios[scenario] = { id: scenario };
      }
    }
    // remove not existing ones
    newScenarios = Object.fromEntries(Object.entries(newScenarios).filter(([key]) => loadedScenarios.includes(key)));
    setStore({ scenarios: newScenarios });
    // if selectedScenario does not exist
    if (selectedScenarioId && !loadedScenarios.includes(selectedScenarioId)) {
      setStore({ selectedScenario: null });
    }
  };

  const setSelectedScenario = (scenarioName) => {
    setStore({ selectedScenario: scenarioName });
  };
  return (
    <div id="scenarios">
      <Header title={title} scenario={selectedScenarioId} />
      <Navbar>
        <ScenarioButtonWidget selectedScenario={selectedScenarioId} />
      </Navbar>
      <Content>
        <Sidebar>
          <SidebarButton label="Scenarios" isActive={true} route={`/scenarios/load`} />
          {selectedScenarioId && <SidebarButton label="Jobs" route={`/scenarios/${selectedScenarioId}`} />}
        </Sidebar>
        <Main>
          <Switch>
            <Route exact path={path}>
              {extra ? (
                <ScenarioLoad setScenarios={setScenarios} />
              ) : (
                <ScenarioList scenarios={scenarios} setSelectedScenario={setSelectedScenario} />
              )}
            </Route>
          </Switch>
        </Main>
      </Content>
    </div>
  );
};

const ScenarioLoad = ({ setScenarios }) => {
  const history = useHistory();
  const [timerHandler, setTimerHandler] = useState(null);
  const [error, setError] = useState(null);
  const colorTheme = colorMapping['UNKNOWN'];
  let isMounted = false;
  const handleApiCall = () => {
    if (!isMounted) return;
    getScenarios()
      .then((response) => {
        if (!isMounted) return;
        const { error, data } = response;
        if (error) {
          throw error;
        }
        return setScenarios(data);
      })
      .then(() => {
        isMounted && history.push(`/scenarios`);
      })
      .catch((error) => {
        console.log('Error', error);
        if (isMounted) {
          setError(error);
          const timerId = setTimeout(() => {
            handleApiCall();
          }, ERROR_RETRY_TIMEOUT);
          setTimerHandler(timerId);
        }
      });
  };

  useEffect(() => {
    isMounted = true; // eslint-disable-line react-hooks/exhaustive-deps
    handleApiCall();
    return () => {
      setError(null);
      timerHandler && clearTimeout(timerHandler) && setTimerHandler(null);
      isMounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {error ? <AppError error={error} setError={setError} /> : null}
      <div className="w3-right">
        <img src={spinner} className="w3-margin-bottom w3-padding w3-spin" alt="Loading..."></img>
      </div>
      <div className={`w3-block ${colorTheme} w3-large w3-margin-bottom w3-padding w3-mobile w3-border`}>Scenarios</div>
    </>
  );
};

const ScenarioList = ({ scenarios, setSelectedScenario }) => {
  const history = useHistory();
  const clickHandler = (scenarioName) => {
    setSelectedScenario(scenarioName);
    history.push(`/scenarios/${scenarioName}`);
  };

  useEffect(() => {
    const scenarioKeysArray = scenarios ? Object.keys(scenarios) : [];
    if (scenarioKeysArray.length === 1) {
      const scenarioName = scenarioKeysArray[0];
      clickHandler(scenarioName);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div id="scenarios_container" className="w3-border" style={{ maxWidth: 600 + 'px' }}>
      <div className="w3-container w3-theme-d1">
        <h3>Select Scenario</h3>
      </div>
      <div>
        {scenarios && Object.keys(scenarios).length ? (
          Object.values(scenarios).map((scenario) => (
            <ScenarioDisplayButton key={scenario.id} scenarioName={scenario.id} setSelectedScenario={clickHandler} />
          ))
        ) : (
          <h3>No data available</h3>
        )}
      </div>
    </div>
  );
};

export const ScenarioButtonWidget = ({ selectedScenario }) => {
  const history = useHistory();

  const clickHandler = () => {
    history.push(`/scenarios/load`);
  };
  return (
    <>
    {selectedScenario &&
      <div className={`w3-bar-item ${colorMapping['UNKNOWN']} w3-button w3-ripple w3-mobile w3-border`} onClick={clickHandler}>{selectedScenario}</div>}
    </>
  );
};

const ScenarioDisplayButton = ({ scenarioName, setSelectedScenario }) => {
  return (
    <div
      className="w3-button w3-ripple w3-block w3-mobile"
      title="scenarioItemButton"
      onClick={() => {
        setSelectedScenario(scenarioName);
      }}
    >
      <ScenarioDisplay scenarioName={scenarioName} />
    </div>
  );
};

const ScenarioDisplay = ({ scenarioName }) => {
  const colorTheme = colorMapping['UNKNOWN'];
  return <span className={`w3-tag w3-large ${colorTheme} w3-padding`}>{scenarioName}</span>;
};

export { ScenarioLoad, ScenarioDisplay, ScenarioDisplayButton, ScenarioList };
