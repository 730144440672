import { React, useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import Header from './Header';
import Navbar from './Navbar';
import { JobButonWidget } from './Jobs';
import { ScenarioButtonWidget } from './Scenarios';
import { CalcMethodButtonWidget } from './CalcMethods';
import { OpeningPeriodDisplay } from './OpenReportingPeriod';
import Content from './Content';
import { Sidebar, SidebarButton } from './Sidebar';
import { createUpdateObject } from './ReportingDates';
import Main from './Main';
import { Info, WarningDialog, AppError } from './Alert';
import { colorMapping } from '../lib/mappings';
import { getPreparations, getReportingDates } from '../adapters/apiCalls';
import spinner from '../assets/images/refresh.png';

const preparationTasks = {
  'Load Reference Files': { id: 'Load Reference Files', pathEnding: 'load-references' },
  'Open Quarterly Reporting Period': { id: 'Open Quarterly Reporting Period', pathEnding: 'quarterly-open' },
  'Open Yearly Reporting Period': { id: 'Open Yearly Reporting Period', pathEnding: 'yearly-open' },
};
const ERROR_RETRY_TIMEOUT = Number(process.env.REACT_APP_ERROR_RETRY_TIMEOUT) || 0;

const Preparations = ({ job, updateJob }) => {
  return <PreparationsView job={job} updateJob={updateJob} />;
};

const PreparationsView = ({ job, updateJob }) => {
  let { url, path } = useRouteMatch();
  const { scenarioId } = useParams();
  const [activeView, setActiveView] = useState('');
  const jobId = job.id;
  const preparations = job.preparations;
  const activeReportingDateObject = job.activeReportingDate ? job.reportingDates[job.activeReportingDate] : null;
  const selectedReportingDateObject = job.selectedReportingDate ? job.reportingDates[job.selectedReportingDate] : null;
  const selectedCalcMethod = job.selectedReportingDate ? job.reportingDates[job.selectedReportingDate].selectedCalcMethod : null;

  const setSelectedPreparation = (taskName) => {
    updateJob({ selectedPreparation: taskName }, jobId);
  };

  const setReportingDates = (loadedDates) => {
    const updateObject = createUpdateObject(loadedDates, job);
    updateObject.preparations = { ...preparationTasks };
    updateJob(updateObject, jobId);
  };

  return (
    <div id="preparations">
      <Header scenario={scenarioId} activeReportingDate={activeReportingDateObject} />
      <Navbar>
        <ScenarioButtonWidget selectedScenario={scenarioId} />
        <JobButonWidget selectedScenario={scenarioId} selectedJob={jobId} />
        {job.selectedReportingDate ? (
          <CalcMethodButtonWidget
            selectedJob={jobId}
            selectedScenario={scenarioId}
            selectedReportingDate={selectedReportingDateObject}
            selectedCalcMethod={selectedCalcMethod}
          />
        ) : null}
      </Navbar>
      <Content>
        <Sidebar>
          <SidebarButton label="Scenarios" route="/scenarios/load" />
          <SidebarButton label="Jobs" route={`/scenarios/${scenarioId}`} />
          <SidebarButton label="Tasks" route={`/scenarios/${scenarioId}/jobs/preparation`} isActive={!activeView}/>
          {preparations &&
            Object.values(preparations).map((task) => <SidebarButton key={task.pathEnding} label={task.id} route={`${url}/${task.pathEnding}`} isActive={activeView === task.pathEnding} />)}
        </Sidebar>
        <Main>
          <Switch>
            <Route path={`${path}/quarterly-open`}>
              <OpeningPeriodDisplay job={job} scenario={scenarioId} period="Quarterly" setActiveView={() => setActiveView('quarterly-open')} />
            </Route>
            <Route path={`${path}/yearly-open`}>
              <OpeningPeriodDisplay job={job} scenario={scenarioId} period="Yearly" setActiveView={() => setActiveView('yearly-open')} />
            </Route>
            <Route path={`${path}/load-references`}>
              <ReferenceDisplay job={job} scenario={scenarioId} setActiveView={() => setActiveView('load-references')} />
            </Route>
            <Route path={path}>
              <PreparationList job={job} setSelectedPreparation={setSelectedPreparation} setReportingDates={setReportingDates} setActiveView={() => setActiveView('')} />
            </Route>
          </Switch>
        </Main>
      </Content>
    </div>
  );
};

const PreparationList = ({ job, setSelectedPreparation, setReportingDates, setActiveView }) => {
  const preparations = job.preparations;
  const history = useHistory();
  const [error, setError] = useState(null);
  const [timerHandler, setTimerHandler] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  let isMounted = false;
  const { scenarioId } = useParams();
  const clickHandler = (taskName) => {
    if (!isLoading) {
      setSelectedPreparation(taskName);
      const pathEnding = preparations[taskName].pathEnding;
      history.push(`/scenarios/${scenarioId}/jobs/preparation/${pathEnding}`);
    }
  };

  const handleApiCall = () => {
    if (!isMounted) return;
    setIsLoading(true);
    getReportingDates(scenarioId)
      .then((response) => {
        if (!isMounted) return;
        const { error, data } = response;
        if (error) {
          throw error;
        }
        return setReportingDates(data);
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log('Error', error);
        if (isMounted) {
          setError(error);
          const timerId = setTimeout(() => {
            handleApiCall();
          }, ERROR_RETRY_TIMEOUT);
          setTimerHandler(timerId);
        }
      });
  };

  useEffect(() => {
    isMounted = true; // eslint-disable-line react-hooks/exhaustive-deps
    handleApiCall();
    setActiveView();
    return () => {
      setError(null);
      timerHandler && clearTimeout(timerHandler) && setTimerHandler(null);
      isMounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {error ? (
        <AppError error={error} setError={setError} />
      ) : (
        <div className="w3-border">
          <div className="w3-container w3-theme-d1">
            <div className="w3-right">
              <img src={spinner} className={`w3-margin-bottom w3-padding${isLoading ? ' w3-spin' : ''}`} alt="Loading..."></img>
            </div>
            <h3>Select Preparation Task</h3>
          </div>
          <div>
            {preparations &&
              Object.keys(preparations).map((taskName) => (
                <PreparationDisplayButton key={taskName} taskName={taskName} clickHandler={clickHandler} />
              ))}
          </div>
        </div>
      )}
    </>
  );
};

const PreparationDisplayButton = ({ taskName, clickHandler }) => {
  const colorTheme = colorMapping['UNKNOWN'];
  return (
    <div
      className="w3-button w3-ripple w3-block w3-mobile"
      title="JobItemButton"
      onClick={() => {
        clickHandler(taskName);
      }}
    >
      <div className={`w3-tag w3-large ${colorTheme} w3-padding`} style={{ width: '70%' }}>
        {taskName}
      </div>
    </div>
  );
};

const ReferenceDisplay = ({ job, scenario, setActiveView, state = '' }) => {
  const warningMessage = `Are you sure you want to load reference files for scenario '${scenario}'?`;
  let { url } = useRouteMatch();
  let [status, setStatus] = useState(state);
  const history = useHistory();
  const goBack = () => {
    setStatus(null);
    history.goBack();
  };
  const goTotasks = () => {
    setStatus(null);
    history.go(-3);
  };
  const goToLoad = () => {
    setStatus('load');
    history.push(`${url}/load`);
  };
  const goToResponse = () => {
    setStatus('response');
    history.push(`${url}/response`);
  };

  useEffect(() => {
    setActiveView();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!status && <WarningDialog message={warningMessage} confirmHandler={goToLoad} cancelHandler={goBack} />}
      {status === 'load' && <ReferenceLoad job={job} goToResponse={goToResponse} />}
      {status === 'response' && <Info message={`Load references workflow for scenario '${scenario}' is started.`} confirmHandler={goTotasks} />}
    </>
  );
};

const ReferenceLoad = ({ job, goToResponse }) => {
  const reportingDateId = job.activeReportingDate;
  const jobId = job.id;
  const [timerHandler, setTimerHandler] = useState(null);
  const { scenarioId } = useParams();
  const [error, setError] = useState(null);
  const colorTheme = colorMapping[jobId];
  let isMounted = false;

  const handleApiCall = () => {
    if (!isMounted) return;
    getPreparations(scenarioId, reportingDateId)
      .then((response) => {
        if (!isMounted) return;
        const { error } = response;
        if (error) {
          throw error;
        }
        goToResponse();
      })
      .catch((error) => {
        console.log('Error', error);
        if (isMounted) {
          setError(error);
          const timerId = setTimeout(() => {
            handleApiCall();
          }, ERROR_RETRY_TIMEOUT);
          setTimerHandler(timerId);
        }
      });
  };

  useEffect(() => {
    isMounted = true; // eslint-disable-line react-hooks/exhaustive-deps
    handleApiCall();
    return () => {
      setError(null);
      timerHandler && clearTimeout(timerHandler) && setTimerHandler(null);
      isMounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {error ? <AppError error={error} setError={setError} /> : null}
      <div className="w3-right">
        <img src={spinner} className="w3-margin-bottom w3-padding w3-spin" alt="Loading..."></img>
      </div>
      <div className={`w3-block ${colorTheme} w3-large w3-margin-bottom w3-padding w3-mobile w3-border`}>Loading...</div>
    </>
  );
};

export { PreparationsView, ReferenceDisplay, ReferenceLoad, Preparations, PreparationList, PreparationDisplayButton };
