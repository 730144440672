import { overAllStatusRemapping } from './mappings'

export const getRemappedStatus = (status) => {
  return overAllStatusRemapping[status] || status;
};

export const sortExecutionList = (dataArray) => {
  const failed = [];
  const inProgress = [];
  const done = [];
  dataArray.forEach((item) => {
    if (item.status === 'Success') {
      done.push(item);
    } else if (item.status === 'Failed') {
      failed.push(item);
    } else {
      inProgress.push(item);
    }
  });
  return [...failed, ...inProgress, ...done];
};

export const transformExecutionList = (executionArray, legerityMark) => {
  return executionArray.reduce((values, item) => {
    values[item] = { value: item, status: 'UNKNOWN', legerityAllowed: item === legerityMark ? true : false };
    return values;
  }, {});
}
