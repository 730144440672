import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { TitledDetailedReportingDateDisplay } from './ReportingDates';
import { Info, WarningDialog, AppError } from './Alert';
import { postOpeningPeriod } from '../adapters/apiCalls';
import { colorMapping } from '../lib/mappings';
import spinner from '../assets/images/refresh.png';

const ERROR_RETRY_TIMEOUT = Number(process.env.REACT_APP_ERROR_RETRY_TIMEOUT) || 0;

export const OpeningPeriodDisplay = ({ job, scenario, period, setActiveView, state = '' }) => {
  const warningMessage = 'Are you sure you want to close current Yearly Reporting Period and Open a new one?';
  let { url } = useRouteMatch();
  let [status, setStatus] = useState(state);
  const history = useHistory();
  const goBack = () => {
    setStatus(null);
    history.goBack();
  };
  const goTotasks = () => {
    setStatus(null);
    history.go(-3);
  };
  const goToLoad = () => {
    setStatus('load');
    history.push(`${url}/load`);
  };
  const goToResponse = () => {
    setStatus('response');
    history.push(`${url}/response`);
  };

  useEffect(() => {
    setStatus();
    setActiveView();
  }, [period]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!status && period === 'Quarterly' && <QuarterlyOpenPeriodDisplay job={job} scenario={scenario} goToLoad={goToLoad} />}
      {!status && period === 'Yearly' && <WarningDialog message={warningMessage} confirmHandler={goToLoad} cancelHandler={goBack} />}
      {status === 'load' && <WaitingOpeningPeriodResponse job={job} goToResponse={goToResponse} scenarioId={scenario} period={period} />}
      {status === 'response' && <Info message={`Open ${period} Reporting Period workflow is started.`} confirmHandler={goTotasks} />}
    </>
  );
};

export const QuarterlyOpenPeriodDisplay = ({ job, scenario, goToLoad }) => {
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const history = useHistory();
  const { activeReportingDate, newReportingDate } = job;
  const newRepDatObj = job.reportingDates && job.reportingDates[newReportingDate] ? job.reportingDates[newReportingDate] : {};
  const activeRepDatObj = job.reportingDates && job.reportingDates[activeReportingDate] ? job.reportingDates[activeReportingDate] : {};
  const warningMessage = `Are you sure you want to ${
    activeReportingDate ? `close period '${activeReportingDate}' and` : ''
  } open period '${newReportingDate}'?`;
  const clickHandler = () => {
    setIsButtonClicked(true);
  };
  const confirmHandler = async () => {
    goToLoad();
  };
  const cancelHandler = () => {
    setIsButtonClicked(false);
    history.goBack();
  };

  return (
    <>
      <div id="scenario_content" className="w3-border w3-margin">
        <div className="w3-container">
          <h3>
            Scenario: <span>{scenario}</span>
          </h3>
        </div>
      </div>
      <div id="ard_close_content" className={`${activeReportingDate ? 'w3-border' : ''} w3-margin`}>
        {activeReportingDate ? (
          <TitledDetailedReportingDateDisplay title="Close" subTitle="Active Reporting Date" selectedReportingDate={activeRepDatObj} />
        ) : (
          <Info message="No 'Active Reporting Period' yet, nothing to close." />
        )}
      </div>
      <div id="nrd_open_content" className={`${newReportingDate ? 'w3-border' : ''} w3-margin`}>
        {newReportingDate ? (
          <TitledDetailedReportingDateDisplay title="Open (Activate)" subTitle="New Reporting Date" selectedReportingDate={newRepDatObj} />
        ) : (
          <Info message="No 'New Reporting Period' yet, create one first." />
        )}
      </div>
      {!isButtonClicked && scenario && newReportingDate ? (
        <p className="w3-button w3-ripple w3-theme-dark w3-large w3-margin-left w3-left" onClick={clickHandler}>
          Closing &amp; Opening period
        </p>
      ) : null}
      {isButtonClicked && scenario && newReportingDate ? (
        <div className="w3-margin">
          <WarningDialog message={warningMessage} confirmHandler={confirmHandler} cancelHandler={cancelHandler} />
        </div>
      ) : null}
    </>
  );
};

export const WaitingOpeningPeriodResponse = ({ job, goToResponse, scenarioId, period }) => {
  const colorTheme = colorMapping[job.id];
  const [timerHandler, setTimerHandler] = useState(null);
  const [error, setError] = useState(null);
  let isMounted = false;

  const handleApiCall = () => {
    if (!isMounted) return;
    postOpeningPeriod(scenarioId, period)
      .then((response) => {
        if (!isMounted) return;
        const { error } = response;
        if (error) {
          throw error;
        }
      })
      .then(() => {
        isMounted && goToResponse();
      })
      .catch((error) => {
        console.log('Error', error);
        if (isMounted) {
          setError(error);
          const timerId = setTimeout(() => {
            handleApiCall();
          }, ERROR_RETRY_TIMEOUT);
          setTimerHandler(timerId);
        }
      });
  };

  useEffect(() => {
    isMounted = true; // eslint-disable-line react-hooks/exhaustive-deps
    handleApiCall();
    return () => {
      setError(null);
      timerHandler && clearTimeout(timerHandler) && setTimerHandler(null);
      isMounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {error ? <AppError error={error} setError={setError} /> : null}
      <div className="w3-right">
        <img src={spinner} className="w3-margin-bottom w3-padding w3-spin" alt="Loading..."></img>
      </div>
      <div className={`w3-block ${colorTheme} w3-large w3-margin-bottom w3-padding w3-mobile w3-border`}>Waiting for API response...</div>
    </>
  );
};
