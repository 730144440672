import React from 'react';

export const Alert = React.memo((props) => {
  return (
    <div className={`w3-panel ${props.color} w3-border`}>
      {props.title ? <h2>{props.title}</h2> : null}
      <p className="w3-badge w3-left w3-white w3-large w3-margin w3-border">{props.alertType}</p>
      {props.appError && props.appError.code ? (
        <p>
          <span>API response status:</span>{' '}
          <span className="w3-medium">
            <strong>{props.appError.code}</strong>
          </span>
        </p>
      ) : null}
      {props.appError && props.appError.message ? <p>{props.appError.message}</p> : null}
      {props.appError ? <p>{JSON.stringify(props.appError)}</p> : null}
      {props.message ? <p>{props.message}</p> : null}
      {props.confirmHandler || props.cancelHandler ? (
        <div className="w3-bar">
          {props.confirmHandler ? (
            <p className="w3-bar-item w3-button w3-ripple w3-theme-dark w3-large w3-margin-left w3-left" onClick={props.confirmHandler}>
              OK
            </p>
          ) : null}
          {props.cancelHandler ? (
            <p className="w3-bar-item w3-button w3-ripple w3-theme-dark w3-large w3-margin-left w3-left" onClick={props.cancelHandler}>
              Cancel
            </p>
          ) : null}
        </div>
      ) : null}
    </div>
  );
});

export const Warning = React.memo((props) => {
  return <Alert alertType="W" color="w3-pale-yellow" message={props.message} />;
});

export const Error = React.memo((props) => {
  return <Alert alertType="E" color="w3-pale-red" message={props.message} />;
});

export const Info = React.memo((props) => {
  return <Alert alertType="I" color="w3-pale-blue" message={props.message}  confirmHandler={props.confirmHandler} />;
});

export const WarningDialog = React.memo((props) => {
  return (
    <Alert alertType="W" color="w3-pale-yellow" message={props.message} confirmHandler={props.confirmHandler} cancelHandler={props.cancelHandler} />
  );
});

export const AppError = React.memo((props) => {
  const { error, setError } = props;
  const confirmHandler = () => {
    setError(null);
  };
  return <Alert alertType="E" color="w3-pale-red" confirmHandler={confirmHandler} title="Application Error" appError={error} />;
});
