import { React, useEffect, useState } from 'react';
import spinner from '../assets/images/refresh.png';
import { getExecutionIds, getPreprocessingState, getPreprocessingError, getWorkFlowStatus, startLegerity } from '../adapters/apiCalls';
import { Switch, Route, useRouteMatch, useHistory, useParams } from 'react-router-dom';
import Header from './Header';
import Navbar from './Navbar';
import Content from './Content';
import Main from './Main';
import { calcMethods, tcIntegratedCalcMethods, menuItem } from './App';
import { JobButonWidget } from './Jobs';
import { ScenarioButtonWidget } from './Scenarios';
import { ReportingDateButtonWidget } from './ReportingDates';
import { CalcMethodButtonWidget } from './CalcMethods';
import { colorMapping } from '../lib/mappings';
import { getRemappedStatus } from '../lib/WorkflowHelper';
import { Sidebar, SidebarButton } from './Sidebar';
import { TcExecution, TcExecutionView } from './TranspositionCapability';
import { AppError } from './Alert';
const ERROR_RETRY_TIMEOUT = Number(process.env.REACT_APP_ERROR_RETRY_TIMEOUT) || 0;

export const Executions = ({ calcMethod, updateCalcMethod, getActiveReportingDate, getSelectedReportingDate }) => {
  let { path } = useRouteMatch();
  const selectedExecutionId = calcMethod ? calcMethod.selectedExecution : null;
  const selectedExecution = selectedExecutionId ? calcMethod.executions[selectedExecutionId] : null;

  return (
    <Switch>
      <Route path={path}>
        <ExecutionsView
          calcMethod={calcMethod}
          selectedExecution={selectedExecution}
          selectedExecutionId={selectedExecutionId}
          updateCalcMethod={updateCalcMethod}
          getActiveReportingDate={getActiveReportingDate}
          getSelectedReportingDate={getSelectedReportingDate}
        />
      </Route>
    </Switch>
  );
};

export const ExecutionsView = ({ calcMethod, selectedExecution, selectedExecutionId, updateCalcMethod, getActiveReportingDate, getSelectedReportingDate }) => {
  let { path } = useRouteMatch();
  const { jobId, scenarioId, reportingDateId, calcMethodId } = useParams();
  const reportingDate = getSelectedReportingDate();
  const activeReportingDate = getActiveReportingDate() || {};
  const isActiveReportingDate = activeReportingDate.value === reportingDate.value;
  const selectedCalcMethodId = calcMethodId;
  const title = `ifrs17/${jobId}/${scenarioId}/${reportingDateId}/${calcMethodId}/executions`;
  const calculationMethods = { ...calcMethods };

  const setExecutions = (loadedExecutions) => {
    let newExecutions = calcMethod.executions ? calcMethod.executions : loadedExecutions;
    for (const execution of Object.values(loadedExecutions)) {
      const executionId = execution.value;
      if (newExecutions[executionId]) {
        // Update 'legerityAllowed' (status update happens in browser does not come from API)
        newExecutions[executionId].legerityAllowed = execution.legerityAllowed;
      }
      if (!newExecutions[executionId]) {
        newExecutions[executionId] = execution;
      }
    }
    // remove not existing ones
    const loadedExecutionKeys = Object.keys(loadedExecutions);
    newExecutions = Object.fromEntries(Object.entries(newExecutions).filter(([key]) => loadedExecutionKeys.includes(key)));
    const updateObject = { executions: newExecutions };
    if (selectedExecution && !loadedExecutions[selectedExecution.value]) {
      // unset if removed
      updateObject.selectedExecution = null;
    }
    updateCalcMethod(updateObject, calcMethodId);
  };

  const setSelectedExecution = (newSelectedExecution) => {
    updateCalcMethod({ selectedExecution: newSelectedExecution }, calcMethodId);
  };

  const updateExecutions = (updatedExecution, executionId) => {
    // Update state - specific execution gets new details
    const newExecution = { ...selectedExecution, ...updatedExecution };
    const newExecutions = { ...calcMethod.executions, [executionId]: newExecution };
    updateCalcMethod({ executions: newExecutions }, calcMethod.id);
  };

  const setExecutionDetails = (newStatus, newDetails = null) => {
    const newExecution = { ...selectedExecution };
    if (newDetails) {
      newExecution.details = {
        ...selectedExecution.details,
        ...newDetails,
      };
    }
    newExecution.status = newStatus;
    updateExecutions(newExecution, selectedExecution.value);
  };

  return (
    <div id="scenarios">
      <Header title={title} scenario={scenarioId} activeReportingDate={activeReportingDate} />
      <Navbar>
        <ScenarioButtonWidget selectedScenario={scenarioId} />
        <JobButonWidget selectedScenario={scenarioId} selectedJob={jobId} />
        <ReportingDateButtonWidget selectedJob={jobId} selectedScenario={scenarioId} selectedReportingDate={reportingDate} />
        <CalcMethodButtonWidget
          selectedJob={jobId}
          selectedScenario={scenarioId}
          selectedReportingDate={reportingDate}
          selectedCalcMethod={calcMethodId}
        />
        <ExecutionsButtonWidget
          selectedJob={jobId}
          selectedScenario={scenarioId}
          selectedReportingDate={reportingDateId}
          selectedCalcMethod={calcMethodId}
          selectedExecution={selectedExecution}
        />
      </Navbar>
      <Content>
        <Sidebar>
          <SidebarButton label="Scenarios" route="/scenarios/load" />
          <SidebarButton label="Jobs" route={`/scenarios/${scenarioId}`} />
          <SidebarButton label="Reporting dates" route={`/scenarios/${scenarioId}/jobs/${jobId}`} />
          {Object.keys(calculationMethods).map((calcMethodId) => (
            <SidebarButton
              label={calcMethodId}
              key={calcMethodId}
              isActive={selectedCalcMethodId === calcMethodId ? true : false}
              route={`/scenarios/${scenarioId}/jobs/${jobId}/reporting_dates/${reportingDateId}/calc_methods/load/${calcMethodId}`}
              calcType={calcMethodId}
              selectedExecutionId={selectedExecutionId}
            />
          ))}
        </Sidebar>
        <Main>
          <Switch>
            <Route exact path={path}>
              <ExecutionsLoad selectedCalcMethod={calcMethod} setExecutions={setExecutions} />
            </Route>
            <Route path={`${path}/tc_execution`}>
              <TcExecution selectedCalcMethod={calcMethod} updateCalcMethod={updateCalcMethod} />
            </Route>
            <Route path={`${path}/executions/:execId`}>
              <ExecutionDetails
                selectedCalcMethod={calcMethod}
                setExecutionDetails={setExecutionDetails}
                isActiveReportingDate={isActiveReportingDate}
              />
            </Route>
            <Route path={`${path}/executions`}>
              <ExecutionsList selectedCalcMethod={calcMethod} setSelectedExecution={setSelectedExecution} />
            </Route>
          </Switch>
        </Main>
      </Content>
    </div>
  );
};

export const ExecutionsLoad = ({ selectedCalcMethod, setExecutions }) => {
  const history = useHistory();
  const [timerHandler, setTimerHandler] = useState(null);
  const { jobId, scenarioId, reportingDateId } = useParams();
  const [error, setError] = useState(null);
  const selectedCalcMethodId = selectedCalcMethod.id;
  const colorTheme = colorMapping[jobId];
  let isMounted = false;
  const handleApiCall = () => {
    if (!isMounted) return;
    getExecutionIds(reportingDateId, scenarioId, selectedCalcMethodId)
      .then((response) => {
        if (!isMounted) return;
        const { error, data } = response;
        if (error) {
          throw error;
        }
        return setExecutions(data);
      })
      .then(() => {
        isMounted &&
          history.push(`/scenarios/${scenarioId}/jobs/${jobId}/reporting_dates/${reportingDateId}/calc_methods/${selectedCalcMethodId}/executions`);
      })
      .catch((error) => {
        console.log('Error', error);
        if (isMounted) {
          setError(error);
          const timerId = setTimeout(() => {
            handleApiCall();
          }, ERROR_RETRY_TIMEOUT);
          setTimerHandler(timerId);
        }
      });
  };

  useEffect(() => {
    isMounted = true; // eslint-disable-line react-hooks/exhaustive-deps
    handleApiCall();
    return () => {
      setError(null);
      timerHandler && clearTimeout(timerHandler) && setTimerHandler(null);
      isMounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {error && <AppError error={error} setError={setError} />}
      <div className="w3-right">
        <img src={spinner} className="w3-margin-bottom w3-padding w3-spin" alt="Loading..."></img>
      </div>
      <div className={`w3-block ${colorTheme} w3-large w3-margin-bottom w3-padding w3-mobile w3-border`}>Executions</div>
    </>
  );
};

export const ExecutionsList = ({ selectedCalcMethod, setSelectedExecution }) => {
  const { jobId, scenarioId, reportingDateId } = useParams();
  const calcMethodId = selectedCalcMethod.id;
  const history = useHistory();
  const executions = selectedCalcMethod.executions;

  const clickHandler = (executionName) => {
    setSelectedExecution(executionName);
    history.push(
      `/scenarios/${scenarioId}/jobs/${jobId}/reporting_dates/${reportingDateId}/calc_methods/${calcMethodId}/executions/${executionName}`,
    );
  };

  return (
    <div id="executions_container" className="w3-border" style={{ maxHeight: '800px' }}>
      <div className="w3-container w3-theme-d1">
        <h3>Select Execution</h3>
      </div>
      <div>
        {executions && Object.keys(executions).length ? (
          Object.values(executions)
            .sort()
            .reverse()
            .map((execution) => <ExecutionButton key={execution.value} execution={execution} clickHandler={clickHandler} />)
        ) : (
          <h3>No executions can be found</h3>
        )}
      </div>
    </div>
  );
};

export const ExecutionsButtonWidget = ({ selectedJob, selectedScenario, selectedReportingDate, selectedCalcMethod, selectedExecution }) => {
  const selectedExecutionId = selectedExecution ? selectedExecution.value : null;
  const status = selectedExecution ? getRemappedStatus(selectedExecution.status) : 'UNKNOWN';
  const colorTheme = colorMapping[status] || '';
  const history = useHistory();

  const clickHandler = () => {
    history.push(`/scenarios/${selectedScenario}/jobs/${selectedJob}/reporting_dates/${selectedReportingDate}/calc_methods/${selectedCalcMethod}`);
  };

  return (
    <>
      {selectedExecutionId && <div className={`w3-bar-item ${colorTheme} w3-button w3-ripple w3-mobile w3-border`} onClick={clickHandler}>
        {selectedExecutionId}
      </div>}
    </>
  );
};

export const ExecutionButton = ({ execution, clickHandler }) => {
  return (
    <div
      className="w3-button w3-ripple w3-block w3-mobile"
      title="ExecutionItemButton"
      onClick={() => {
        clickHandler(execution.value);
      }}
    >
      <ExecutionDisplay execution={execution} />
    </div>
  );
};

export const ExecutionDisplay = ({ execution }) => {
  const colorTheme = colorMapping[getRemappedStatus(execution.status)];

  return (
    <>
      <span className={`w3-tag w3-large ${colorTheme} w3-padding`} style={{ width: '70%' }}>
        {execution.value}
      </span>
    </>
  );
};

export const ExecutionDetails = ({ selectedCalcMethod, setExecutionDetails, isActiveReportingDate }) => {
  const executionId = selectedCalcMethod.selectedExecution;
  const execution = selectedCalcMethod.executions[executionId];
  const status = execution.status;
  let isMounted = false;
  const { scenarioId, reportingDateId } = useParams();
  const calcMethodId = selectedCalcMethod.id;
  const [isLoading, setIsLoading] = useState(false);
  const [timerHandler, setTimerHandler] = useState(null);
  const [error, setError] = useState(null);
  const colorTheme = colorMapping[getRemappedStatus(status)];

  const reRunAfterTimeout = (fn) => {
    const timerId = setTimeout(() => {
      isMounted && setIsLoading(true);
      fn();
      clearTimeout(timerHandler) && setTimerHandler(null);
    }, ERROR_RETRY_TIMEOUT);
    setTimerHandler(timerId);
  };

  const handleTcExecutions = (data) => {
    // TODO: this can/should be moved to backend
    if (!tcIntegratedCalcMethods.includes(calcMethodId) || data.status !== 'Success') {
      return data.status;
    }
    let filtered = [];
    if (tcIntegratedCalcMethods.includes(calcMethodId)) {
      filtered = data.files.filter((elem) => elem.fileName.includes('CashFlowPv') || elem.fileName.includes('ImportedMeasure'));
    }
    return filtered.length ? data.status : 'In Progress';
  };

  const handlePreprocessingApiCall = () => {
    if (!isMounted) return;
    getPreprocessingState(reportingDateId, scenarioId, calcMethodId, executionId)
      .then((response) => {
        if (!isMounted) return;
        setIsLoading(false);
        const { error, data } = response;
        if (error) {
          throw error;
        }
        const status = handleTcExecutions(data);
        setExecutionDetails(status, { preprocessing: data.files });
        return status;
      })
      .then((status) => {
        if (isMounted && status && ['UNKNOWN', 'In Progress'].includes(status)) {
          reRunAfterTimeout(handlePreprocessingApiCall);
        }
      })
      .catch((error) => {
        console.log('Error', error);
        if (isMounted) {
          setError(error);
          reRunAfterTimeout(handlePreprocessingApiCall);
        }
      });
  };

  const handleWorkflowDetailApiCall = () => {
    if (!isMounted) return;
    getWorkFlowStatus(reportingDateId, scenarioId, calcMethodId, executionId)
      .then((response) => {
        if (!isMounted) return;
        isMounted && setIsLoading(false);
        const { error, data } = response;
        if (error) {
          throw error;
        }
        setExecutionDetails(data.status, { legerityOrchestrator: data.stages });
        return data.status;
      })
      .then((status) => {
        if (isMounted && status && ['Success', 'RUNNING'].includes(status)) {
          reRunAfterTimeout(handleWorkflowDetailApiCall);
        }
      })
      .catch((error) => {
        if (isMounted) {
          if (error && error.code === 404 && error.message.includes('NOT FOUND - Workflow does not exist')) {
            // If status is 'NOT STARTED' and it is the active reporting date, start legerity button is to put out
            return setExecutionDetails('NOT STARTED');
          }
          setError(error);
          reRunAfterTimeout(handleWorkflowDetailApiCall);
        }
        console.log('Error', error);
      });
  };

  useEffect(() => {
    isMounted = true; // eslint-disable-line react-hooks/exhaustive-deps
    // It starts when execId changes and detail API-s are not yet called, or the execution is not in end state
    if (execution) {
      // If preprocessing data is not fetched or preprocessing state is not an end state: fetch data
      if (!execution.details || ['UNKNOWN', 'In Progress'].includes(status)) {
        setIsLoading(true);
        handlePreprocessingApiCall();
      }
      // If preprocessing done, or orchestrator is in progress,
      // it needs to fetch the workflow details that belongs to the execution id
      if (['Success', 'RUNNING'].includes(status)) {
        setIsLoading(true);
        handleWorkflowDetailApiCall();
      }
    }

    return () => {
      timerHandler && clearTimeout(timerHandler) && setTimerHandler(null);
      isMounted = false;
    };
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {error && <AppError error={error} setError={setError} />}
      <div className="w3-right">
        <img src={spinner} className={isLoading ? 'w3-margin-bottom w3-padding w3-spin' : 'w3-margin-bottom w3-padding'} alt="Loading..."></img>
      </div>
      <div className={`w3-block ${colorTheme} w3-large w3-margin-bottom w3-padding w3-mobile w3-border`}>Status: {getRemappedStatus(status)}</div>
      {status === 'NOT STARTED' && isActiveReportingDate && execution.legerityAllowed && (
        <StartLegerityButton executionId={executionId} setExecutionDetails={setExecutionDetails} setError={setError} />
      )}
      {execution.details && ['RUNNING', 'SUCCEEDED', 'FAILED', 'TIMED_OUT', 'ABORTED'].includes(status) && (
        <OrchestratorView details={execution.details.legerityOrchestrator} calcMethodId={calcMethodId}/>
      )}
      {tcIntegratedCalcMethods.includes(calcMethodId) && execution.details && ['In Progress', 'NOT STARTED'].includes(status) && (
        <TcExecutionView details={execution.details} setError={setError} status={status} legerityAllowed={execution.legerityAllowed} />
      )}
      {execution.details && <PreprocessingView details={execution.details} />}
    </>
  );
};

export const StartLegerityButton = ({ executionId, setExecutionDetails, setError }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [timerHandler, setTimerHandler] = useState(null);
  const { scenarioId, reportingDateId, calcMethodId } = useParams();
  let isMounted = false;

  const handleStartWorkflowApiCall = () => {
    if (!isMounted) return;
    startLegerity(reportingDateId, scenarioId, calcMethodId, executionId)
      .then((response) => {
        if (!isMounted) return;
        setIsLoading(false);
        const { error } = response;
        if (error && !(error.code === 400 && error.message.includes('Cannot Start Workflow, it has been started before'))) {
          throw error;
        }
        setExecutionDetails('RUNNING');
      })
      .catch((error) => {
        console.log('Error', error);
        if (isMounted) {
          setError(error);
          const timerId = setTimeout(() => {
            isMounted && setIsLoading(true);
            handleStartWorkflowApiCall();
            clearTimeout(timerHandler) && setTimerHandler(null);
          }, ERROR_RETRY_TIMEOUT);
          setTimerHandler(timerId);
        }
      });
  };

  const clickHandler = () => {
    if (!isLoading && !timerHandler) {
      setIsLoading(true);
      handleStartWorkflowApiCall();
    }
  };

  useEffect(() => {
    isMounted = true; // eslint-disable-line react-hooks/exhaustive-deps
    return () => {
      timerHandler && clearTimeout(timerHandler) && setTimerHandler(null);
      isMounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w3-button w3-ripple w3-theme-dark w3-large w3-margin-bottom w3-padding w3-mobile" title="Start Legerity" onClick={clickHandler}>
      <span>Start FastPost</span>
      {isLoading ? <img src={spinner} className="w3-spin w3-margin-left" alt="Loading..." style={{ height: '24px' }}></img> : null}
    </div>
  );
};

export const OrchestratorStageView = ({ stageStatus, stageName }) => {
  const colorTheme = colorMapping[stageStatus];

  return (
    <div className="w3-bar w3-mobile w3-margin-left" style={{ width: '100%' }}>
      <div className="w3-bar-item" style={{ width: '70%' }}>
        {stageName}:
      </div>
      <div style={{ width: '120px' }} className={`w3-bar-item w3-large ${colorTheme}`}>
        {stageStatus}
      </div>
    </div>
  );
};

export const OrchestratorView = ({ details, calcMethodId }) => {
  const isData = details && Array.isArray(details) ? details.length : false;
  return (
    <div className={details ? 'w3-border w3-margin-bottom' : ''}>
      {details && (
        <>
          <div className="w3-container w3-theme-d1">
            <h3>FastPost Orchestrator Result</h3>
          </div>
          <div className="w3-margin">
            {isData ? (
              details.map((stage) =>
              <>
              {calcMethodId === menuItem.releasecsm &&  stage.name === 'Execute Batch Feeder Workflow' &&
              <OrchestratorStageView key={stage.name + 'RLRC'} stageName='RLRC Execution' stageStatus={stage.status === 'Failed' ? 'UNKNOWN' : stage.status} />
              }
              <OrchestratorStageView key={stage.name} stageName={stage.name} stageStatus={stage.status} />
              {calcMethodId === menuItem.releasecsm &&  stage.name === 'Execute Batch Feeder Workflow' &&
              <OrchestratorStageView key={stage.name + 'PF'} stageName='Portfolio Execution' stageStatus={stage.status === 'Failed' ? 'UNKNOWN' : stage.status} />
              }
              </>)
            ) : (
              <h3>No data available yet</h3>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const PreprocessingItemView = ({ inputFile, showModal }) => {
  const inputFileName = inputFile.fileName ? inputFile.fileName.split('/').pop() : null;
  const splitS3BucketName = (inputFile) => {
    return inputFile.fileName.split('/').splice(3).join('/');
  };

  const validatorClickHandler = (inputFile) => {
    showModal(splitS3BucketName(inputFile), 'validator');
  };
  const splitterClickHandler = (inputFile) => {
    showModal(splitS3BucketName(inputFile), 'splitter');
  };
  return (
    <div className="w3-margin-bottom">
      <div className="w3-bar-block w3-mobile" style={{ width: '100%' }}>
        <div className="w3-bar-item w3-large">{inputFileName}</div>
      </div>
      <div className="w3-bar w3-mobile w3-margin-left" style={{ width: '100%' }}>
        <div className="w3-bar-item" style={{ width: '100px' }}>
          Validator:
        </div>
        {inputFile.validator === 'Failed' ? (
          <PreprocessingItemDetailButton
            item={inputFile.validator}
            clickHandler={() => {
              validatorClickHandler(inputFile);
            }}
          />
        ) : (
          <PreprocessingItemDetailView item={inputFile.validator} />
        )}
      </div>
      <div className="w3-bar w3-mobile w3-margin-left" style={{ width: '100%' }}>
        <div className="w3-bar-item" style={{ width: '100px' }}>
          Splitter:
        </div>
        {inputFile.splitter === 'Failed' ? (
          <PreprocessingItemDetailButton
            item={inputFile.splitter}
            clickHandler={() => {
              splitterClickHandler(inputFile);
            }}
          />
        ) : (
          <PreprocessingItemDetailView item={inputFile.splitter} />
        )}
      </div>
    </div>
  );
};

export const PreprocessingItemDetailView = ({ item }) => {
  return (
    <div style={{ width: '120px' }} className={`w3-bar-item w3-large ${colorMapping[item]}`}>
      {item}
    </div>
  );
};

export const ModalPopup = ({ toggleModal, s3Key, preprocessigType }) => {
  const { scenarioId, reportingDateId, calcMethodId, execId } = useParams();
  const [error, setError] = useState(null);
  const [timerHandler, setTimerHandler] = useState(null);
  const [errorLogs, setErrorLogs] = useState('Loading...');
  let isMounted = false;

  const addErrorDetails = (data) => {
    setErrorLogs(data);
  };

  const handleApiCall = () => {
    if (!isMounted) return;
    getPreprocessingError(s3Key, preprocessigType, scenarioId, reportingDateId, calcMethodId, execId)
      .then((response) => {
        if (!isMounted) return;
        const { error, data } = response;
        if (error) {
          throw error;
        }
        return addErrorDetails(data);
      })
      .catch((error) => {
        console.log('Error', error);
        if (isMounted) {
          setError(error);
          const timerId = setTimeout(() => {
            handleApiCall();
          }, ERROR_RETRY_TIMEOUT);
          setTimerHandler(timerId);
        }
      });
  };

  useEffect(() => {
    isMounted = true; // eslint-disable-line react-hooks/exhaustive-deps
    handleApiCall();
    return () => {
      setError(null);
      timerHandler && clearTimeout(timerHandler) && setTimerHandler(null);
      setErrorLogs('Loading...');
      isMounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w3-container">
      <div id="id01" className="w3-modal">
        <div className="w3-modal-content w3-animate-top" style={{ width: '90%' }}>
          {error ? (
            <AppError error={error} setError={setError} />
          ) : (
            <>
              <h2>Error logs:</h2>
              <div className="w3-container">
                <span onClick={() => toggleModal(null, null)} className="w3-button w3-display-topright">
                  &times;
                </span>
                {errorLogs.split('\n').map((log, idx) => (
                  <p key={idx}>{log}</p>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const PreprocessingItemDetailButton = ({ item, clickHandler }) => {
  return (
    <div style={{ width: '120px' }} className={`w3-bar-item w3-large w3-button w3-ripple ${colorMapping[item]}`} onClick={clickHandler}>
      {item}
    </div>
  );
};

export const PreprocessingView = ({ details }) => {
  const isData = details.preprocessing && details.preprocessing.length;
  const prefix = isData && details.preprocessing[0].fileName ? details.preprocessing[0].fileName.split('/').slice(0, -1).join('/') : '';
  const [isShow, setIsShow] = useState(false);
  const [_preprocessigType, setPreprocessingType] = useState(null);
  const [_s3Key, setS3Key] = useState(null);
  const toggleModal = (s3Key, preprocessigType) => {
    setS3Key(s3Key);
    setPreprocessingType(preprocessigType);
    setIsShow(!isShow);
  };
  return (
    <>
      <div className="w3-border">
        <div className="w3-container w3-theme-d1">
          <h3>Preprocessing Results</h3>
        </div>
        {isData ? (
          <>
            <div>Input prefix: {prefix}</div>
            <div>
              {Object.values(details.preprocessing).map((file) => (
                <PreprocessingItemView key={file.fileName} inputFile={file} showModal={toggleModal} />
              ))}
            </div>
          </>
        ) : (
          <h3>No data available yet</h3>
        )}
      </div>
      {isShow && <ModalPopup toggleModal={toggleModal} s3Key={_s3Key} preprocessigType={_preprocessigType} />}
    </>
  );
};
