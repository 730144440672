import { React, useEffect } from 'react';
import { Switch, Route, useRouteMatch, useParams, useHistory } from 'react-router-dom';
import { colorMapping } from '../lib/mappings';
import Header from './Header';
import Navbar from './Navbar';
import { calcMethods } from './App';
import { JobButonWidget } from './Jobs';
import { ScenarioButtonWidget } from './Scenarios';
import { ReportingDateButtonWidget } from './ReportingDates';
import { Executions } from './Executions';
import Content from './Content';
import { Sidebar, SidebarButton } from './Sidebar';
import Main from './Main';

export const CalcMethod = ({ reportingDate, getActiveReportingDate, updateReportingDate }) => {
  let { path } = useRouteMatch();
  const selectedReportingDateId = reportingDate.value;
  const selectedCalcMethodId = reportingDate.selectedCalcMethod;
  const selectedCalcMethod = selectedCalcMethodId && reportingDate.calcMethods ? reportingDate.calcMethods[selectedCalcMethodId] : {};

  const getSelectedReportingDate = () => reportingDate;

  const updateCalcMethod = (updateCalcMethod, calcMethodId) => {
    // Update app state with extended details of calcMethod
    const newCalcMethod = { ...selectedCalcMethod, ...updateCalcMethod };
    const newCalcMethods = { ...reportingDate.calcMethods, [calcMethodId]: newCalcMethod };
    updateReportingDate({ calcMethods: newCalcMethods }, selectedReportingDateId);
  };

  const setCalcMethods = () => {
    if (reportingDate.calcMethods) {
      // if it is set nothing to do
      return;
    }
    updateReportingDate({ calcMethods: { ...calcMethods } }, selectedReportingDateId);
  };

  const setSelectedCalcMethod = (calcMethodName) => {
    updateReportingDate({ selectedCalcMethod: calcMethodName }, selectedReportingDateId);
  };

  return (
    <Switch>
      <Route exact path={path}>
        <CalcMethodView reportingDate={reportingDate} getActiveReportingDate={getActiveReportingDate} setSelectedCalcMethod={setSelectedCalcMethod} />
      </Route>
      <Route exact path={`${path}/load`}>
        <CalcMethodView
          reportingDate={reportingDate}
          getActiveReportingDate={getActiveReportingDate}
          setSelectedCalcMethod={setSelectedCalcMethod}
          setCalcMethods={setCalcMethods}
          extra="load"
        />
      </Route>
      <Route path={`${path}/load/:methodName`}>
        <CalcMethodView
          reportingDate={reportingDate}
          getActiveReportingDate={getActiveReportingDate}
          setSelectedCalcMethod={setSelectedCalcMethod}
          setCalcMethods={setCalcMethods}
          extra="loadMethod"
        />
      </Route>
      <Route path={`${path}/:calcMethodId`}>
        <Executions
          calcMethod={selectedCalcMethod}
          updateCalcMethod={updateCalcMethod}
          getActiveReportingDate={getActiveReportingDate}
          getSelectedReportingDate={getSelectedReportingDate}
        />
      </Route>
    </Switch>
  );
};

export const CalcMethodButtonWidget = ({ selectedJob, selectedScenario, selectedReportingDate, selectedCalcMethod }) => {
  const history = useHistory();
  const selectedReportingDateId = selectedReportingDate && selectedReportingDate.value;
  const colorTheme = colorMapping[selectedJob];

  const clickHandler = () => {
    history.push(`/scenarios/${selectedScenario}/jobs/${selectedJob}/reporting_dates/${selectedReportingDateId}/calc_methods/load`);
  };

  return (
    <>
      {selectedCalcMethod && <div className={`w3-bar-item ${colorTheme} w3-button w3-ripple w3-mobile w3-border`} onClick={clickHandler}>
        {selectedCalcMethod}
      </div>}
    </>
  );
};

const CalcMethodView = ({ reportingDate, setSelectedCalcMethod, setCalcMethods, getActiveReportingDate, extra }) => {
  const { jobId, scenarioId, reportingDateId, methodName } = useParams();
  const history = useHistory();
  let { path } = useRouteMatch();
  const activeReportingDateObject = getActiveReportingDate();
  const selectedReportingDateId = reportingDate.value;
  const selectedCalcMethodId = reportingDate.selectedCalcMethod;
  const calcMethods = reportingDate.calcMethods;
  const title = `ifrs17/${jobId}/${scenarioId}/${selectedReportingDateId}/calc-methods`;
  const selectedExecutionId = calcMethods ? calcMethods.selectedExecution : null;
  const selectedExecution = selectedExecutionId ? calcMethods.executions[selectedExecutionId] : null;

  useEffect(() => {
    if (!extra) {
      return;
    }
    if (!calcMethods) {
      setCalcMethods();
    }
    if (methodName) {
      setSelectedCalcMethod(methodName);
      return history.replace(`/scenarios/${scenarioId}/jobs/${jobId}/reporting_dates/${reportingDateId}/calc_methods/${methodName}`);
    }
    history.replace(`/scenarios/${scenarioId}/jobs/${jobId}/reporting_dates/${reportingDateId}/calc_methods`);
  }, [extra]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div id="calcMethods">
      <Header title={title} scenario={scenarioId} activeReportingDate={activeReportingDateObject} />
      <Navbar>
        <ScenarioButtonWidget selectedScenario={scenarioId} />
        <JobButonWidget selectedScenario={scenarioId} selectedJob={jobId} />
        <ReportingDateButtonWidget selectedJob={jobId} selectedScenario={scenarioId} selectedReportingDate={reportingDate} />
        <CalcMethodButtonWidget
          selectedJob={jobId}
          selectedScenario={scenarioId}
          selectedReportingDate={reportingDate}
          selectedCalcMethod={selectedCalcMethodId}
        />
      </Navbar>
      <Content>
        <Sidebar>
          <SidebarButton label="Scenarios" route="/scenarios/load" />
          <SidebarButton label="Jobs" route={`/scenarios/${scenarioId}`} />
          <SidebarButton label="Reporting dates" route={`/scenarios/${scenarioId}/jobs/${jobId}`} />
          {calcMethods && (
            Object.keys(calcMethods).map((calcMethodId) => (
              <SidebarButton
                label={calcMethodId}
                key={calcMethodId}
                isActive={selectedCalcMethodId === calcMethodId ? true : false}
                route={`/scenarios/${scenarioId}/jobs/${jobId}/reporting_dates/${selectedReportingDateId}/calc_methods/load/${calcMethodId}`}
              />
            ))
          )}
        </Sidebar>
        <Main>
          <Switch>
            <Route path={`${path}`}>
              <CalcMethodList calcMethods={calcMethods} setSelectedCalcMethod={setSelectedCalcMethod} extra={extra} />
            </Route>
          </Switch>
        </Main>
      </Content>
    </div>
  );
};

const CalcMethodList = ({ calcMethods, setSelectedCalcMethod, extra }) => {
  const { jobId, scenarioId, reportingDateId } = useParams();
  const history = useHistory();
  const clickHandler = (calcMethodName) => {
    setSelectedCalcMethod(calcMethodName);
    history.push(`/scenarios/${scenarioId}/jobs/${jobId}/reporting_dates/${reportingDateId}/calc_methods/${calcMethodName}`);
  };

  useEffect(() => {
    if (calcMethods) {
      const calcKeysArray = Object.keys(calcMethods);
      if (calcKeysArray.length === 1) {
        const calcMethodName = calcKeysArray[0];
        clickHandler(calcMethodName);
      }
    }
  }, [calcMethods, extra]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div id="calcs_container" className="w3-border">
      <div className="w3-container w3-theme-d1">
        <h3>Select Calculation Method</h3>
      </div>
      <div>
        {calcMethods &&
          Object.keys(calcMethods).map((calcMethodName) => (
            <CalcMethodButton key={calcMethodName} calcMethodName={calcMethodName} clickHandler={clickHandler} />
          ))}
      </div>
    </div>
  );
};

const CalcMethodButton = ({ calcMethodName, clickHandler }) => {
  return (
    <div
      className="w3-button w3-ripple w3-block w3-mobile"
      title="CalcMethodItemButton"
      onClick={() => {
        clickHandler(calcMethodName);
      }}
    >
      <CalcMehodDisplay calcMethodName={calcMethodName} />
    </div>
  );
};

const CalcMehodDisplay = ({ calcMethodName }) => {
  const { jobId } = useParams();
  const colorTheme = colorMapping[jobId];

  return (
    <>
      <span className={`w3-tag w3-large ${colorTheme} w3-padding`} style={{ width: '70%' }}>
        {calcMethodName}
      </span>
    </>
  );
};

export { CalcMethodView, CalcMethodList, CalcMethodButton, CalcMehodDisplay };
