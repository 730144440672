import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {Scenarios} from './Scenarios';

export const StoreContext = React.createContext()
// No API needed, hardcoded values
export const jobNames = {
  calculations: { id: 'calculations' },
  preparation: { id: 'preparation' }
};

export const menuItem = { releasecsm: 'RLRC Allocation & ReleaseCSM & Portfolio Allocation'}

export const calcMethods = {
  PAA: { id: 'PAA' },
  GMM: { id: 'GMM' },
  VFA: { id: 'VFA' },
  REO_PAA: { id: 'REO_PAA' },
  REO_GMM: { id: 'REO_GMM' },
  [menuItem.releasecsm]: { id: menuItem.releasecsm },

};

export const tcIntegratedCalcMethods = [ 'GMM', 'VFA', 'REO_GMM' ];

export default class App extends React.Component {
  state = {};

  _isMounted = false;

  componentDidMount = () => {
    this._isMounted = true;
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  setStore = (newState) => {
    this._isMounted &&
      this.setState((prevState) => ({
        ...prevState,
        ...newState
      }));
  };

  isStoreEmpty = () => {
    return !Object.keys(this.state).length;
  };

  render() {
    return (
      <StoreContext.Provider value={{isStoreEmpty: this.isStoreEmpty}}>
        <Switch>
          <Route path="/scenarios">
            <Scenarios store={this.state} setStore={this.setStore} />
          </Route>
          <Route path="/">
            <Redirect to="/scenarios/load" />
          </Route>
        </Switch>
        </StoreContext.Provider>
    );
  }
}
