import React from 'react';

const Main = ({ children }) => {
  return (
    <main id="main" className="w3-rest w3-mobile w3-padding w3-center" style={{ maxWidth: '600px' }}>
      {children}
    </main>
  );
};
export default Main;
