export const Event = function() {
  this.subscribers = [];
  this.attach = (subscriberFn) => {
    this.subscribers.push(subscriberFn);
  };
  this.detach = (subscriberFn) => {
    this.subscribers = this.subscribers.filter(item => item !== subscriberFn);
  };
  this.notify = (message) => {
    this.subscribers.forEach(subscriberFn => subscriberFn(message));
  };
};
