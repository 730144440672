import React from 'react';
import { useHistory } from 'react-router-dom';
import { tcIntegratedCalcMethods } from './App';

export const Sidebar = ({ children }) => {
  return (
    <aside id="sidebar" className="w3-quarter w3-bar-block w3-mobile">
      <h3 className="w3-center">Workflows</h3>
      {children}
    </aside>
  );
};

export const SidebarButton = ({ label, isActive, route, calcType, selectedExecutionId }) => {
  const history = useHistory();
  const extraClasses = label && isActive ? ` w3-large w3-theme-light` : '';

  return (
    <>
      <div className={`w3-bar-item w3-button w3-ripple w3-mobile${extraClasses}`} onClick={() => history.push(route)}>
        {label}
      </div>
      {calcType && isActive && <>
        {tcIntegratedCalcMethods.includes(calcType) && <div className={`w3-bar-item w3-button w3-ripple w3-mobile w3-margin-left`} onClick={() => 
          history.push(`${route.split('/load/')[0]}/${calcType}/tc_execution`)}>
          TC execution
        </div>}
        {selectedExecutionId && <div className={`w3-bar-item w3-button w3-ripple w3-mobile w3-margin-left`} onClick={() => 
          history.push(`${route.split('/load/')[0]}/${calcType}/executions/${selectedExecutionId}`)}>
          <span>Selected execution:</span> <span>{selectedExecutionId}</span>
        </div>}
      </>}
    </>
  );
};
