import { React, useEffect, useState, useRef } from 'react';
import spinner from '../assets/images/refresh.png';
import { startTCExecution, getTCExecution, getTCResults, startCopy } from '../adapters/apiCalls';
import { useParams } from 'react-router-dom';
import { colorMapping } from '../lib/mappings';
import { getRemappedStatus } from '../lib/WorkflowHelper';
import { Info, AppError } from './Alert';
import { Event } from '../lib/SimpleEventing';
const ERROR_RETRY_TIMEOUT = Number(process.env.REACT_APP_ERROR_RETRY_TIMEOUT) || 0;

const tcInfo = {};
export const TcExecution = ({ selectedCalcMethod, updateCalcMethod }) => {
  let savedManifest;
  try {
    savedManifest =
      selectedCalcMethod && selectedCalcMethod.selectedTcExecution && selectedCalcMethod.selectedTcExecution.manifest
        ? JSON.parse(selectedCalcMethod.selectedTcExecution.manifest)
        : null;
  } catch (e) {}
  const manifest = useRef();
  const { jobId, scenarioId, reportingDateId } = useParams();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowInfo, setIsShowInfo] = useState(false);
  const [tcDetails, setTcDetails] = useState(null);

  const [tcDetailsEvent] = useState(new Event());
  const [startInfoEvent] = useState(new Event());
  const [tcExecutionEvent] = useState(new Event());
  const [startTcEvent] = useState(new Event());
  const selectedCalcMethodId = selectedCalcMethod.id;
  const colorTheme = colorMapping[jobId];

  const getManifestContent = () => {
    try {
      return JSON.stringify(JSON.parse(manifest.current.value));
    } catch (error) {
      setError('Manifest content provided is invalid.');
    }
  };

  const retry = (customEvent) => {
    clearTimeout(tcInfo.timerHandler);
    const timerId = setTimeout(() => {
      customEvent.notify();
    }, ERROR_RETRY_TIMEOUT);
    tcInfo.timerHandler = timerId;
  };

  const getTcExecution = () => {
    if (isLoading) {
      return;
    }
    clearTimeout(tcInfo.timerHandler);
    tcInfo.timerHandler = null;
    const manifestStr = getManifestContent();
    if (!manifestStr) {
      return;
    }
    setIsLoading(true);
    getTCExecution(reportingDateId, scenarioId, selectedCalcMethodId, manifestStr)
      .then((response) => {
        const { error, data } = response;
        if (error) {
          throw error;
        }
        return tcDetailsEvent.notify(data);
      })
      .catch((error) => {
        console.log('Error', error);
        setIsLoading(false);
        setError(error);
        retry(tcExecutionEvent);
      });
  };

  const setTcDetailsState = (data) => {
    setIsLoading(false);
    return setTcDetails(data);
  };

  const setStartInfoState = () => {
    setIsLoading(false);
    return setIsShowInfo(true);
  };

  const startTC = () => {
    if (isLoading) {
      return;
    }
    clearTimeout(tcInfo.timerHandler);
    tcInfo.timerHandler = null;
    const manifestStr = getManifestContent();
    if (!manifestStr) {
      return;
    }
    setIsLoading(true);
    startTCExecution(reportingDateId, scenarioId, selectedCalcMethodId, manifestStr)
      .then((response) => {
        const { error } = response;
        if (error) {
          throw error;
        }
        return startInfoEvent.notify();
      })
      .catch((error) => {
        console.log('Error', error);
        setIsLoading(false);
        setError(error);
        retry(startTcEvent);
      });
  };

  const saveContent = () => {
    const updateObject = { selectedTcExecution: { manifest: manifest.current.value } };
    updateCalcMethod(updateObject, selectedCalcMethodId);
  };

  useEffect(() => {
    manifest.current.value = savedManifest ? JSON.stringify(savedManifest, null, 2) : '';
    tcDetailsEvent.attach(setTcDetailsState);
    startInfoEvent.attach(setStartInfoState);
    tcExecutionEvent.attach(getTcExecution);
    startTcEvent.attach(startTC);
    return () => {
      tcDetailsEvent.detach(setTcDetailsState);
      startInfoEvent.detach(setStartInfoState);
      tcExecutionEvent.detach(getTcExecution);
      startTcEvent.detach(startTC);
      setError(null);
      setIsLoading(false);
      clearTimeout(tcInfo.timerHandler);
      tcInfo.timerHandler = null;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tcDetails && !['F', 'S'].includes(tcDetails.status)) {
      retry(tcExecutionEvent);
    }
  }, [tcDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {error ? <AppError error={error} setError={setError} /> : null}
      <div className="w3-right">
        <img src={spinner} className={`w3-margin-bottom w3-padding${isLoading ? ' w3-spin' : ''}`} alt="Loading..."></img>
      </div>
      <div className={`w3-block ${colorTheme} w3-large w3-margin-bottom w3-padding w3-mobile w3-border`}>TC Execution</div>
      {tcDetails && <TcDetailsView tcDetails={tcDetails} updateCalcMethod={updateCalcMethod} />}
      <form className="w3-container w3-border">
        <p>
          <label htmlFor="manifestcontent">Paste here content of 'manifest.json'</label>
          <textarea id="manifestcontent" className="w3-input" ref={manifest} rows="9" />
        </p>
      </form>
      <TcExecutionButton
        label="Start TC"
        clickHandler={() => {
          saveContent();
          startTcEvent.notify();
        }}
      />
      <TcExecutionButton
        label="Get Details"
        clickHandler={() => {
          saveContent();
          tcExecutionEvent.notify();
        }}
      />
      {isShowInfo && <Info message="Transposition Capability has been started. Notice if you use the same manifest content TC only runs first time." confirmHandler={() => setIsShowInfo(false)} />}
    </>
  );
};

export const TcDetailsView = ({ tcDetails }) => {
  const [showDetails, setShowDetails] = useState(true);
  const tcDetailColorTheme = tcDetails ? colorMapping[getRemappedStatus(tcDetails.status)] : 'w3-theme-dark';

  return (
    <>
      <div className="w3-margin-bottom">
        <div
          className={`w3-block ${tcDetailColorTheme} w3-large w3-padding w3-mobile w3-border w3-button w3-ripple`}
          onClick={() => {
            setShowDetails(!showDetails);
          }}
        >
          <span>Details</span> <span>&#9660;</span>
        </div>
        {showDetails && (
          <div className="w3-padding w3-border-left w3-border-right w3-border-bottom" style={{ overflowX: 'auto' }}>
            <p>
              <span>Status:</span> <span>{getRemappedStatus(tcDetails.status || 'R')}</span>
            </p>
            {tcDetails.status === 'F' && (
              <p>
                <span>ERROR:</span>
                <br /> {tcDetails.error}
              </p>
            )}
            {tcDetails.status === 'S' && (
              <div>
                <span>Output files:</span>
                <br />
                {JSON.parse(tcDetails.output_files).map((file) => (
                  <p key={file}>{file}</p>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export const TcExecutionButton = ({ label, clickHandler }) => {
  return (
    <div className="w3-button w3-ripple w3-theme-dark w3-large w3-margin-left w3-margin-bottom" onClick={() => clickHandler()}>
      {label}
    </div>
  );
};

export const TcExecutionView = ({ details, setError, status, legerityAllowed }) => {
  const { scenarioId, reportingDateId, calcMethodId, execId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [timerHandler, setTimerHandler] = useState(null);
  const [showDetails, setShowDetails] = useState(true);
  const [showCopyInfo, setShowCopyInfo] = useState(false);
  const [tcOutputFilelist, setTcOutputFilelist] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [queryFilesEvent] = useState(new Event());
  const [copyEvent] = useState(new Event());
  const [updateFileListEvent] = useState(new Event());
  const [updateViewEvent] = useState(new Event());
  const copiedFiles =
    details.preprocessing && details.preprocessing.length
      ? details.preprocessing.filter((elem) => elem.fileName.includes('CashFlowPv') || elem.fileName.includes('ImportedMeasure'))
      : [];
  const isTCData = copiedFiles.length;
  const isShowButtons = legerityAllowed && !['RUNNING', 'SUCCEEDED', 'FAILED', 'TIMED_OUT', 'ABORTED'].includes(status) ? true : false;

  const setTcResultState = (files) => {
    setIsLoading(false);
    const newFiles = files.reduce((acc, item) => {
      let calcMethIdx = item.indexOf(`/${calcMethodId.toLowerCase()}/`);
      if (calcMethIdx < 0) {
        calcMethIdx = item.indexOf(`/${calcMethodId}/`);
      }
      if (calcMethIdx < 0) {
        throw Error(`Bad configuration - calculation method name (${calcMethodId}) can not be found in path of TC result files: ${files}`);
      }
      const short = item
        .slice(calcMethIdx + 1)
        .split('/')
        .slice(1, 3)
        .join('/');
      acc.push({ key: short, value: item });
      return acc;
    }, []);
    setTcOutputFilelist(newFiles.length ? newFiles : [{ key: 'No data returned', value: 'Not found' }]);
  };

  const setCopyInfoState = () => {
    setIsLoading(false);
    setShowCopyInfo(true);
    setSelectedFiles([]);
    setTcOutputFilelist([]);
  };

  const retry = (customEvent) => {
    clearTimeout(timerHandler);
    const timerId = setTimeout(() => {
      customEvent.notify();
    }, ERROR_RETRY_TIMEOUT);
    setTimerHandler(timerId);
  };

  const queryTcResults = () => {
    if (isLoading) {
      return;
    }
    clearTimeout(timerHandler);
    setTimerHandler(null);
    setIsLoading(true);
    getTCResults(reportingDateId, scenarioId, calcMethodId)
      .then((response) => {
        const { error, data } = response;
        if (error) {
          throw error;
        }
        return updateFileListEvent.notify(data.files);
      })
      .catch((error) => {
        console.log('Error', error);
        setIsLoading(false);
        setError(error);
        retry(queryFilesEvent);
      });
  };

  const startCopyTcResults = () => {
    if (!selectedFiles.length) {
      setError('Nothing to copy');
      return;
    }
    if (isLoading) {
      return;
    }
    clearTimeout(timerHandler);
    setTimerHandler(null);
    setIsLoading(true);
    startCopy(reportingDateId, scenarioId, calcMethodId, execId, selectedFiles)
      .then((response) => {
        const { error } = response;
        if (error) {
          throw error;
        }
        return updateViewEvent.notify();
      })
      .catch((error) => {
        console.log('Error', error);
        setIsLoading(false);
        setError(error);
        retry(copyEvent);
      });
  };

  const selectionHandler = (filePath, add) => {
    if (add) {
      setSelectedFiles([...selectedFiles, filePath]);
      return;
    }
    setSelectedFiles(selectedFiles.filter((file) => file !== filePath));
  };

  useEffect(() => {
    queryFilesEvent.attach(queryTcResults);
    copyEvent.attach(startCopyTcResults);
    updateFileListEvent.attach(setTcResultState);
    updateViewEvent.attach(setCopyInfoState);
    return () => {
      queryFilesEvent.detach(queryTcResults);
      copyEvent.detach(startCopyTcResults);
      updateFileListEvent.detach(setTcResultState);
      updateViewEvent.detach(setCopyInfoState);
      setError(null);
      setIsLoading(false);
      clearTimeout(timerHandler) && setTimerHandler(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="w3-border w3-margin-bottom">
        <div
          className="w3-container w3-theme-d1 w3-ripple"
          onClick={() => {
            if (showDetails) {
              setSelectedFiles([]);
              setTcOutputFilelist([]);
            }
            setShowDetails(!showDetails);
          }}
        >
          <img src={spinner} className={isLoading ? 'w3-right w3-margin-top w3-spin' : 'w3-right w3-margin-top'} alt="Loading..."></img>
          <h3>
            <span>TC Result Files</span> <span>&#9660;</span>
          </h3>
        </div>
        {showDetails && (
          <div className="w3-margin-top" style={{ overflowX: 'auto' }}>
            {isTCData ? (
              <>
                {copiedFiles.map((file) => (
                  <p key={file.fileName}>{file.fileName}</p>
                ))}
              </>
            ) : (
              <p>No 'CashFlowPv', 'ImportedMeasure' files found</p>
            )}
            {isShowButtons && (
              <TcExecutionButton
                label="Select TC result files"
                clickHandler={() => {
                  queryFilesEvent.notify();
                }}
              />
            )}
            {isShowButtons && (
              <TcExecutionButton
                label="Copy files"
                clickHandler={() => {
                  startCopyTcResults();
                }}
              />
            )}
          </div>
        )}
        {showDetails && tcOutputFilelist.length ? (
          <div className="w3-left-align w3-margin-left w3-margin-bottom" style={{ overflowX: 'auto' }}>
            {tcOutputFilelist.map((item, index) => (
              <TcResultListElement key={index} selectionHandler={selectionHandler} item={item} />
            ))}
          </div>
        ) : null}
        {showCopyInfo && <Info message="Copy done" confirmHandler={() => setShowCopyInfo(false)} />}
      </div>
    </>
  );
};

export const TcResultListElement = ({ item, selectionHandler }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <input
        className="w3-check"
        type="checkbox"
        onChange={(evt) => {
          selectionHandler(item.value, evt.target.checked);
        }}
      />
      <label
        className="w3-margin-left"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <span>{item.key}</span> <span>&#9660;</span>
      </label>
      {isOpen && <div className="w3-margin-left w3-margin-right">{item.value}</div>}
    </div>
  );
};
