import {
  getMockScenarios,
  getMockReportingDates,
  getMockExecutionIds,
  getMockPreprocessingResult,
  getMockPreprocessingError,
  getMockOrchestratorResult,
  getMockPreparations,
  getMockTCExecution,
  getMockStartTCResponse,
  getMockTCResults,
  getMockStartCopy,
} from './mockData';
import { sortData, transformFetchedReportingDates } from '../lib/ReportingDateHelper';
import { sortExecutionList, transformExecutionList } from '../lib/WorkflowHelper';
import { menuItem } from '../components/App';
const SERVER_URL = process.env.REACT_APP_SERVER_URL || window.location.hostname;
export const testUrl = '<PLACEHOLDER-SERVER-URL>';

export const httpGet = async (url) => {
  let response;
  try {
    response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (err) {
    const message = `${err} URL: '${url}'`;
    return { error: { message } };
  }

  let responseData = {};
  try {
    responseData = await response.json();
  } catch (error) {
    console.log(`Can not parse response to json - status: ${response.status}, url: ${url}, error: ${error}, response: ${JSON.stringify(response)}`);
    response.ok = false;
  }

  if (response.ok) {
    const result = { data: responseData.data };
    if (responseData.metadata) {
      result.metadata = responseData.metadata;
    }
    return result;
  }

  const errMessage = `URL: '${url}'; ${responseData['message'] || ':-( Oops,Something went wrong.'}`;
  return {
    error: {
      code: response.status,
      message: errMessage,
    },
  };
};

export const httpPost = async (url, payload) => {
  let response;
  try {
    response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  } catch (err) {
    const message = `${err} URL: '${url}'`;
    //console.debug(`httpPost(): ${message}`)
    return { error: { message } };
  }

  let responseData = await response.json();
  if (!responseData) {
    responseData = {};
  }
  if (response.ok) {
    return { data: responseData.data };
  }

  return {
    error: {
      code: response.status,
      message: `URL: '${url}'; ${responseData['message'] || JSON.stringify(responseData)}`,
    },
  };
};

export const getScenarios = async () => {
  if (SERVER_URL === testUrl) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getMockScenarios());
      }, 2000);
    });
  }
  const url = `${SERVER_URL}/backend/scenarios/`;
  return await httpGet(url);
};

export const getReportingDates = async (scenario) => {
  if (SERVER_URL === testUrl) {
    return new Promise((resolve) => {
      const response = getMockReportingDates();
      if (response.data) {
        response.data = sortData(transformFetchedReportingDates(response.data));
      }
      setTimeout(() => resolve(response), 2000);
    });
  }
  const url = `${SERVER_URL}/backend/scenarios/${scenario}/reporting-dates`;
  const response = await httpGet(url);
  if (response.data) {
    response.data = sortData(transformFetchedReportingDates(response.data));
  }
  return response;
};

export const getExecutionIds = async (selectedReportingDate, scenario, calcModel) => {
  if (SERVER_URL === testUrl) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const response = getMockExecutionIds();
        const result = transformExecutionList(response.data, response.metadata.legerityAllowed);
        resolve({ data: result });
      }, 2000);
    });
  }
  let calcType = calcModel;
  if (calcModel === menuItem.releasecsm) {
    calcType = 'releasecsm';
  }
  const url = `${SERVER_URL}/backend/scenarios/${scenario}/reportings/${selectedReportingDate}/calctypes/${calcType}/execution-ids`;
  const response = await httpGet(url);
  if (response.data) {
    response.data = transformExecutionList(response.data, response.metadata.legerityAllowed);
  }
  return { data: response.data };
};

export const getPreprocessingState = async (selectedReportingDate, scenario, calcModel, selectedExecutionId) => {
  if (SERVER_URL === testUrl) {
    const mockPreprocessingDetails = getMockPreprocessingResult();
    if (mockPreprocessingDetails.data) {
      mockPreprocessingDetails.data.files = sortExecutionList(mockPreprocessingDetails.data.files);
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockPreprocessingDetails);
      }, 2000);
    });
  }
  let calcType = calcModel;
  if (calcModel === menuItem.releasecsm) {
    calcType = 'releasecsm';
  }
  const url = `${SERVER_URL}/backend/scenarios/${scenario}/reportings/${selectedReportingDate}/calctypes/${calcType}/execution-ids/${selectedExecutionId}/preprocessing-status`;
  const response = await httpGet(url);
  if (response.data && response.data.files) {
    response.data.files = sortExecutionList(response.data.files);
  }
  return response;
};

export const getWorkFlowStatus = async (selectedReportingDate, scenario, calcModel, executionId) => {
  if (SERVER_URL === testUrl) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getMockOrchestratorResult());
      }, 2000);
    });
  }
  let calcType = calcModel;
  if (calcModel === menuItem.releasecsm) {
    calcType = 'releasecsm';
  }
  const url = `${SERVER_URL}/backend/scenarios/${scenario}/reportings/${selectedReportingDate}/calctypes/${calcType}/execution-ids/${executionId}/workflow-status`;
  return await httpGet(url);
};

export const startLegerity = async (selectedReportingDate, scenario, calcModel, selectedExecutionId) => {
  if (SERVER_URL === testUrl) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: {} });
      }, 2000);
    });
  }

  let calcType = calcModel;
  if (calcModel === menuItem.releasecsm) {
    calcType = 'releasecsm';
  }
  let url = `${SERVER_URL}/backend/scenarios/${scenario}/reportings/${selectedReportingDate}/calctypes/${calcType}/execution-ids/${selectedExecutionId}/start-calculation`;
  const data = {
    Workflow: 'Calculation',
    Input: {
      executionID: `${selectedExecutionId}`,
      scenarioId: `${scenario}`,
      calcMethodType: `${calcType}`,
      reportingId: `${selectedReportingDate}`,
    },
  };
  return await httpPost(url, data);
};

export const postOpeningPeriod = async (scenario, period) => {
  let workflow;
  if (period === 'Quarterly') {
    workflow = 'QuarterlyOpening';
  }
  if (period === 'Yearly') {
    workflow = 'YearlyOpening';
  }
  if (SERVER_URL === testUrl) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ data: {} });
      }, 2000);
    });
  }
  let url = `${SERVER_URL}/backend/open-reporting-period`;
  const data = {
    Workflow: workflow,
    Input: {
      scenarioId: `${scenario}`,
    },
  };
  return await httpPost(url, data);
};

export const getPreparations = async (scenario, reportingId) => {
  if (SERVER_URL === testUrl) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getMockPreparations());
      }, 2000);
    });
  }
  const url = `${SERVER_URL}/backend/scenarios/${scenario}/reportings/${reportingId}/load-references`;
  const data = {
    Workflow: 'LoadReferences',
  };
  return await httpPost(url, data);
};

export const getPreprocessingError = async (s3Key, preprocessigType, scenarioId, selectedReportingDate, calcModel, selectedExecutionId) => {
  const s3keyArray = s3Key.split('/');
  const filename = s3keyArray.pop();
  const prefix = s3keyArray.join('/');
  if (SERVER_URL === testUrl) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getMockPreprocessingError());
      }, 2000);
    });
  }
  let calcType = calcModel;
  if (calcModel === menuItem.releasecsm) {
    calcType = 'releasecsm';
  }
  const url = `${SERVER_URL}/backend/scenarios/${scenarioId}/reportings/${selectedReportingDate}/calctypes/${calcType}/execution-ids/${selectedExecutionId}/preprocessing-error?type=${preprocessigType}&filename=${filename}&prefix=${prefix}`;
  return await httpGet(url);
};

export const getTCExecution = async (selectedReportingDate, scenario, calcModel, manifestStr) => {
  if (SERVER_URL === testUrl) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getMockTCExecution());
      }, 2000);
    });
  }
  const url = `${SERVER_URL}/backend/scenarios/${scenario}/reportings/${selectedReportingDate}/calctypes/${calcModel}/tc-execution-detail`;
  const data = {
    manifest: manifestStr,
  };
  return await httpPost(url, data);
};

export const startTCExecution = async (selectedReportingDate, scenario, calcModel, manifestStr) => {
  if (SERVER_URL === testUrl) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getMockStartTCResponse());
      }, 2000);
    });
  }
  const url = `${SERVER_URL}/backend/scenarios/${scenario}/reportings/${selectedReportingDate}/calctypes/${calcModel}/start-tc-execution`;
  const data = {
    manifest: manifestStr,
  };
  return await httpPost(url, data);
};

export const getTCResults = async (selectedReportingDate, scenario, calcModel) => {
  if (SERVER_URL === testUrl) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getMockTCResults());
      }, 2000);
    });
  }
  const url = `${SERVER_URL}/backend/scenarios/${scenario}/reportings/${selectedReportingDate}/calctypes/${calcModel}/tc-results`;
  return await httpGet(url);
};

export const startCopy = async (selectedReportingDate, scenario, calcModel, execId, selectedTcResults) => {
  if (SERVER_URL === testUrl) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(getMockStartCopy());
      }, 2000);
    });
  }
  const url = `${SERVER_URL}/backend/scenarios/${scenario}/reportings/${selectedReportingDate}/calctypes/${calcModel}/execution-ids/${execId}/copy-tc-result`;
  const data = {
    tcResults: selectedTcResults,
  };
  return await httpPost(url, data);
};
