import React from 'react';
import logo from '../assets/images/aegon.png';
import { colorMapping } from '../lib/mappings';
const ACCOUNT_ID = process.env.REACT_APP_ACCOUNT_NUMBER;

const Header = ({ activeReportingDate, scenario }) => {
  const title = 'IFRS17';
  const colorTheme = activeReportingDate && activeReportingDate.status? colorMapping[activeReportingDate.status]: '';
  const activeReportingDateId = activeReportingDate && activeReportingDate.value? activeReportingDate.value: '';

  return (
    <div id="header" className="w3-row w3-margin-top w3-margin-bottom">
      <div className="w3-third w3-center  w3-padding">
        <img className="w3-margin" src={logo} alt="Logo" width={120} height={40} />
      </div>
      <div className="w3-third w3-center w3-text-theme w3-xxlarge w3-padding-top-64">
        <strong>{title}</strong>
      </div>
      <div className="w3-third w3-center w3-text-theme-dark w3-padding-top">
        <div className="w3-padding-top">
          <span>AWS Id: </span>
          <strong>{ACCOUNT_ID}</strong>
        </div>
        { scenario? <div className="w3-padding-top">
          <span>Scenario: </span>
          <strong className="w3-large">{scenario}</strong>
        </div>: null }
        { activeReportingDateId? <div className="w3-padding-top">
          <span>Active Reporting Date: </span>
          <span className={`w3-tag ${colorTheme} w3-large`}>{activeReportingDateId}</span>
        </div>: null } 
      </div>
    </div>
  );
};
export default Header;
