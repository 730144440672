import React from 'react';

const Navbar = (props) => {
  return (
    <div id="navbar" className="w3-row">
      <div className="w3-bar w3-theme-dark" id="topnav">
        <div className='w3-bar-item w3-mobile w3-border'>
          <span>Selected items </span>
        </div>
        {props.children}
        </div>
    </div>
  );
};
export default Navbar;
