import React from 'react';

const Content = ({ children }) => {
  return (
    <div id="content" className="w3-row">
      {children}
    </div>
  );
};
export default Content;
