/** Reporting Dates
  Status IDs:
  'N' - New
  'A' - Active
  'C' - Closed
  'R' - Closed, Read Only
 */

export const niceNameMapping = {
  // Reporting Dates
  A: 'Active',
  C: 'Closed',
  R: 'Closed, Read Only',
  N: 'New, Not Active',
};

export const colorMapping = {
  // Jobs
  calculations: 'w3-theme-light',
  preparation: 'w3-grey',
  // Reporting Dates
  A: 'aeg-puertorico',
  C: 'w3-theme',
  R: 'w3-black',
  N: 'w3-theme-light',
  // Workflow/Orchestarator statuses
  UNKNOWN: 'w3-light-grey', // unknown
  // preprocessing
  'In Progress': 'w3-pale-yellow', // in progress
  Success: 'aeg-fern', // preprocessing done
  Failed: 'w3-pale-red', // failed
  // Legerity (orchestarator) run statuses
  'NOT STARTED': 'w3-pale-yellow', // not yet run
  RUNNING: 'w3-pale-yellow', // in progress
  SUCCEEDED: 'aeg-fern', // legerity done
  FAILED: 'w3-pale-red', // legerity failed
  TIMED_OUT: 'w3-pale-red', // legerity failed
  ABORTED: 'w3-pale-red', // legerity failed
  // default colors
  undefined: 'w3-light-grey', // unknown
  null: 'w3-light-grey', // unknown
};

export const overAllStatusRemapping = {
  /* Execution list view:
      R - RUNNING
      F - FAILED
      S - SUCCEEDED
      U - UNKNOWN
    */
  // This map combines all used statuses to a status info
  // that shows the status of the whole process on a higher level
  UNKNOWN: 'UNKNOWN',
  'In Progress': 'RUNNING', // preprocessing state
  Success: 'RUNNING', // preprocessing state
  RUNNING: 'RUNNING', // orchestrator state
  'NOT STARTED': 'RUNNING', // orchestrator state
  R: 'RUNNING', // TC execution status
  Failed: 'FAILED', // preprocessing state
  FAILED: 'FAILED', // orchestrator state
  F: 'FAILED', //  TC execution status
  TIMED_OUT: 'FAILED', // orchestrator state
  ABORTED: 'FAILED', // orchestrator state
  SUCCEEDED: 'SUCCEEDED', // orchestrator state
  S: 'SUCCEEDED', //  TC execution status
};
