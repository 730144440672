import { React, useEffect, useContext } from 'react';
import { useRouteMatch, Switch, Route, useHistory, useParams } from 'react-router-dom';
import Header from './Header';
import Navbar from './Navbar';
import { Sidebar, SidebarButton } from './Sidebar';
import { ReportingDate } from './ReportingDates';
import { jobNames, StoreContext } from './App';
import Content from './Content';
import Main from './Main';
import { ScenarioButtonWidget } from './Scenarios';
import { Preparations } from './Preparations';
import { colorMapping } from '../lib/mappings';
import { capitalizeFirstLetter } from '../lib/miscHelper';

export const Jobs = ({ scenario, updateScenario }) => {
  let { path } = useRouteMatch();
  const history = useHistory();
  const { isStoreEmpty } = useContext(StoreContext);
  if (isStoreEmpty()) {
    history.push('/scenarios/load');
    return <></>;
  }
  const selectedJobId = scenario.selectedJob;
  const jobs = scenario.jobs;
  const selectedJob = jobs && selectedJobId ? jobs[selectedJobId] : null;

  // Pass toward to set state
  const updateJob = (updatedJob, jobId) => {
    if (jobId !== selectedJobId) {
      console.log('Unexpected FATAL ERROR in "Jobs" updateJob()');
      return;
    }
    const newJob = { ...selectedJob, ...updatedJob };
    const newJobs = { ...jobs, [jobId]: newJob };
    updateScenario({ jobs: newJobs }, scenario.id);
  };

  return (
    <Switch>
      <Route path={`${path}/jobs/preparation`}>
          <Preparations job={selectedJob} updateJob={updateJob} />
      </Route>
      <Route path={`${path}/jobs/:jobId`}>
          <ReportingDate job={selectedJob} updateJob={updateJob} />
      </Route>
      <Route path={path}>
        <JobView jobs={jobs} selectedJob={selectedJob} updateScenario={updateScenario} />
      </Route>
    </Switch>
  );
};

const JobView = ({ jobs, selectedJob, updateScenario }) => {
  let { path } = useRouteMatch();
  const { scenarioId } = useParams();
  const selectedJobId = selectedJob ? selectedJob.id : null;
  const activeReportingDateObject = selectedJob &&  selectedJob.activeReportingDate ? selectedJob.reportingDates[selectedJob.activeReportingDate] : null;

  const setJobs = () => {
    if (jobs) {
      return;
    }
    updateScenario({ jobs: { ...jobNames } }, scenarioId);
  };

  const setSelectedJob = (jobName) => {
    updateScenario({ selectedJob: jobName }, scenarioId);
  };

  return (
    <div id="jobs">
      <Header scenario={scenarioId}  activeReportingDate={activeReportingDateObject} />
      <Navbar>
        <ScenarioButtonWidget selectedScenario={scenarioId} />
        <JobButonWidget selectedScenario={scenarioId} selectedJob={selectedJobId} />
      </Navbar>
      <Content>
        <Sidebar>
          <SidebarButton label="Scenarios" route="/scenarios/load" />
          <SidebarButton label="Jobs" isActive={true} route={`/scenarios/${scenarioId}`} />
          {selectedJobId && selectedJobId === 'calculations' && <SidebarButton label="Reporting dates" route={`/scenarios/${scenarioId}/jobs/${selectedJobId}/`} />}
          {selectedJobId && selectedJobId === 'preparation' && <SidebarButton label="Tasks" route={`/scenarios/${scenarioId}/jobs/preparation/`} />}
        </Sidebar>
        <Main>
          <Route path={`${path}/jobs`}>
            <JobList jobs={jobs} setSelectedJob={setSelectedJob} />
          </Route>
          <Route exact path={path}>
            <JobList jobs={jobs} setJobs={setJobs} />
          </Route>
        </Main>
      </Content>
    </div>
  );
};

export const JobButonWidget = ({ selectedScenario, selectedJob }) => {
  const history = useHistory();
  const scenarioId = selectedScenario;

  const clickHandler = () => {
    history.push(`/scenarios/${scenarioId}`);
  };

  return (
    <>
      {selectedJob && <div className={`w3-bar-item ${colorMapping[selectedJob]} w3-button w3-ripple w3-mobile w3-border`} onClick={clickHandler}>
        {capitalizeFirstLetter(selectedJob)}
      </div>}
    </>
  );
};

const JobList = ({ jobs, setJobs, setSelectedJob }) => {
  const history = useHistory();
  const { scenarioId } = useParams();
  const clickHandler = (jobName) => {
    setSelectedJob(jobName);
    history.push(`/scenarios/${scenarioId}/jobs/${jobName}`);
  };

  useEffect(() => {
    if (!jobs) {
      // hardcoded jobs needs only load once
      setJobs();
    }
    history.push(`/scenarios/${scenarioId}/jobs`);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!setJobs) {
      const jobKeysArray = Object.keys(jobs);
      if (jobKeysArray.length === 1) {
        const jobName = jobKeysArray[0];
        clickHandler(jobName);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div id="jobs_container" className="w3-border">
      <div className="w3-container w3-theme-d1">
        <h3>Select Job</h3>
      </div>
      <div>{jobs && Object.keys(jobs).map((jobName) => <JobDisplayButton key={jobName} jobName={jobName} clickHandler={clickHandler} />)}</div>
    </div>
  );
};

const JobDisplayButton = ({ jobName, clickHandler }) => {
  return (
    <div
      className="w3-button w3-ripple w3-block w3-mobile"
      title="JobItemButton"
      onClick={() => {
        clickHandler(jobName);
      }}
    >
      <JobDisplay jobName={jobName} />
    </div>
  );
};

const JobDisplay = ({ jobName }) => {
  const colorTheme = colorMapping[jobName];
  return (
    <>
      <span className={`w3-tag w3-large ${colorTheme} w3-padding`} style={{ width: '70%' }}>
        {capitalizeFirstLetter(jobName)}
      </span>
    </>
  );
};

export { JobView, JobList, JobDisplayButton, JobDisplay };
